import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCarProblemsData } from "../../api/getCarProblemsData";
import { Table, CellAligns } from "../../../../components/Table";
import { Pagination } from "../../../../components/Pagination";
import { useState } from "react";
import classNames from "classnames";
import { SeverityDataTypes } from "../../@types";
import moment from "moment";
import { COOKIE_AUTH_TOKEN } from "../../../../constants";
import Cookies from "js-cookie";

import styles from "./tableWrapper.module.sass";

const severityTrans = {
  [SeverityDataTypes.HIGH]: "Высокий",
  [SeverityDataTypes.NOT_CLASSIFIED]: " NOT_CLASSIFIED",
  [SeverityDataTypes.INFO]: "INFO",
  [SeverityDataTypes.WARNING]: "WARNING",
  [SeverityDataTypes.AVERAGE]: "AVERAGE",
  [SeverityDataTypes.DISASTER]: "DISASTER",
};

export const TableWrapper = () => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);
  const [page, setPage] = useState<number>(0);
  const { id: carId } = useParams<{ id: string }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tripId = searchParams.get("tripId") || undefined;
  const hostId = searchParams.get("hostId") || undefined;

  const { data: problems, isFetching } = useQuery({
    queryKey: ["carTableData", tripId, carId, hostId, page, token],
    queryFn: () => getCarProblemsData({ tripId, carId, hostId, page }),
    refetchOnWindowFocus: false,
  });

  const tableInfoHead = [
    {
      title: "Метка времени",
      align: "left" as CellAligns,
    },
    {
      title: "Уровень проблемы",
      align: "left" as CellAligns,
    },
    {
      title: "Описание проблемы",
      align: "left" as CellAligns,
    },
  ];

  const tableInfoRow = problems?.data.map((el, idx) => ({
    data: [
      moment(el.dateTime).format("DD.MM.YYYY HH:mm"),
      <div className={classNames(styles.severityItem, styles.severityItemHigh)}>
        {severityTrans[el.severity] || el.severity}
      </div>,
      el.description,
    ],
    id: `${idx}`,
  }));

  const paginationCount = Math.round((problems?.total ?? 1) / 5);

  return (
    <div className={styles.tableWrap}>
      <div className={styles.tableTitle}>Проблемы</div>
      <Table head={tableInfoHead} hideSearchField rows={tableInfoRow} />
      <div className={styles.paginationWrap}>
        <Pagination
          disabled={isFetching}
          count={paginationCount}
          size="small"
          hidePrevButton
          hideNextButton
          onChange={(_, page) => setPage(page - 1)}
        />
      </div>
    </div>
  );
};
