import classNames from "classnames";
import { FC, ReactNode, memo, useEffect, useState, useRef } from "react";
import { ReactComponent as IconHome } from "./assets/ic_home.svg";
import { useLocation } from "react-router-dom";

import styles from "./breadcrumb.module.sass";

type BreadcrumbsProps = {
  className?: string;
  children: ReactNode;
};

const BreadcrumbsComp: FC<BreadcrumbsProps> = ({
  className = "",
  children,
}) => {
  const [scroll, setScroll] = useState<number>(0);
  const breadcrumbsRef = useRef<HTMLUListElement>(null);
  const location = useLocation();

  const handleScroll = (e: Event) => {
    setScroll((e.target as HTMLUListElement).scrollLeft);
  };

  useEffect(() => {
    breadcrumbsRef.current?.addEventListener("scroll", (e) => {
      e.preventDefault();
      handleScroll(e);
    });

    return () =>
      breadcrumbsRef.current?.removeEventListener("scroll", handleScroll);
  }, [breadcrumbsRef]);

  return (
    <div
      className={classNames(`${styles.wrap} ${className}`, {
        [styles.wrapScroll]: scroll,
      })}
    >
      <ul ref={breadcrumbsRef} className={styles.list}>
        <li className={styles.listItem}>
          <a className={styles.item} href={`/${location.search}`}>
            <IconHome />
          </a>
        </li>
        {children}
      </ul>
    </div>
  );
};

export const Breadcrumbs = memo(BreadcrumbsComp);
