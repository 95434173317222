import { api } from "../../../api";
import { CarByTripRequest, CarByTripResponse } from "../@types";
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const getCarByTrip = async (
  params: CarByTripRequest
): Promise<CarByTripResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  if (!params?.tripId || !params.carId) return;

  const resp = await api.get(
    `/fpk/trips/${params?.tripId}/cars/${params.carId}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return resp.data;
};
