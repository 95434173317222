import React from "react";
import { IDatePicker, TDayModifier } from "./types";
import { useDatePickerController } from "./useDatePickerController";
import styles from "./datePicker.module.sass";
import cn from "classnames";
import { ReactComponent as CalendarIcon } from "./assets/CalendarIcon.svg";
import { ReactComponent as ChevronLeftIcon } from "./assets/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { getFormattedMonth, getIsDayDisabled } from "./utils";
import { getPeriodStringFormat } from "./utils";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/es";
import "moment/locale/en-au";

const dayDecorator: Record<NonNullable<TDayModifier>, string> = {
  isOneDaySelected: styles.isOneDaySelected,
  isStartOfRange: styles.rangeStart,
  isEndOfRange: styles.rangeEnd,
  inRange: styles.inRange,
  isToday: styles.today,
};

export const DatePicker: React.FC<IDatePicker> = ({
  value,
  placeHolder,
  disabledDays = {
    before: new Date(),
    after: new Date(moment().add(12, "month").format("YYYY-MM-DD")),
  },
  allowedDays,
  limitDays,
  oneDayCalendar,
  oneDayOrRange,
  allowSameDay,
  stringValue,
  icon,
  disabled,
  fullWidth,
  onClick,
  onChange,
  onMonthChange,
}) => {
  const {
    visibleDaysToCurrentMonth,
    currentMonth,
    isOpened,
    wrapperRef,
    weekDays,
    daysModifiers,
    unsaveDates,
    calculatedDisabledDays,
    disableMonths,
    handleOpen,
    handleSelectNextMonth,
    handleSelectPrevMonth,
    handleSelectDay,
    onDayHover,
    handleClose,
  } = useDatePickerController({
    value,
    limitDays,
    disabledDays,
    oneDayCalendar,
    oneDayOrRange,
    onChange,
    onMonthChange,
    allowSameDay,
  });

  const periodString = getPeriodStringFormat(
    value?.from,
    value?.to,
    "ru",
    "DD.MM.YYYY",
    allowSameDay
  );

  const calendar = (
    <div
      className={cn(styles.calendarWrap, "DatePickerCalendar", {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <div className={styles.monthController}>
        <div
          onClick={handleSelectPrevMonth}
          className={cn(disableMonths.prevDisabled && styles.disabledMonth)}
        >
          <ChevronLeftIcon />
        </div>
        <div className={styles.month}>
          {getFormattedMonth({ value: currentMonth, language: "ru" })}
        </div>
        <div
          onClick={handleSelectNextMonth}
          className={cn(disableMonths.nextDisabled && styles.disabledMonth)}
        >
          <ChevronRightIcon />
        </div>
      </div>
      <div className={styles.week}>
        {weekDays.map((weekDay, idx) => {
          return (
            <div key={idx} className={cn(styles.weekDay)}>
              {weekDay}
            </div>
          );
        })}
      </div>
      <div className={styles.days}>
        {visibleDaysToCurrentMonth.map((preparedDay) => {
          const onClickDay = () => {
            handleSelectDay(preparedDay);
          };

          const currentModifiers = daysModifiers?.[preparedDay?.key];
          const decoratorStyle = currentModifiers
            ? dayDecorator[currentModifiers]
            : null;

          const onMouseEnter = () => {
            if (unsaveDates?.from && !unsaveDates.to) {
              onDayHover(preparedDay);
            }
          };

          const isDayDisabled =
            preparedDay.monthPosition !== "currentMonth"
              ? true
              : getIsDayDisabled({
                  before: calculatedDisabledDays?.before ?? null,
                  after: calculatedDisabledDays?.after ?? null,
                  allowedDays: allowedDays ?? null,
                  formattedDay: preparedDay.key,
                  date: preparedDay.day,
                });

          return (
            <div
              key={preparedDay.key}
              className={cn(styles.day, decoratorStyle, {
                [styles.disableDay]: isDayDisabled,
              })}
              onClick={onClickDay}
              onMouseEnter={onMouseEnter}
            >
              {preparedDay.dateDay}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div
      className={styles.wrap}
      ref={wrapperRef}
      onClick={onClick}
      style={{ position: "relative" }}
    >
      <div
        className={cn(styles.select, "DatePickerSelect", {
          [styles.opened]: isOpened,
          [styles.disabled]: disabled,
        })}
        onClick={isOpened ? handleClose : handleOpen}
      >
        <div className={cn(styles.value, "datePickerValue")}>
          {value?.from || value?.to
            ? stringValue ?? periodString
            : placeHolder ?? ""}
        </div>
        <div className={cn(styles.icon)}>{icon ?? <CalendarIcon />}</div>
      </div>
      {isOpened && <div>{calendar}</div>}
    </div>
  );
};
