import classNames from "classnames";
import { AllowedStatuses } from "../../../FilialDetail/@types";
import { MonitorDataItem, MonitorDataTypes } from "../../@types";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";
import { Swiper as SwiperType } from "swiper";
import { ReactComponent as IconIm } from "./assets/ic_im.svg";
import { ReactComponent as IconWifi } from "./assets/ic_wifi.svg";
import { ReactComponent as IconVideo } from "./assets/ic_video.svg";
import { ReactComponent as IconSkdy } from "./assets/ic_skdy.svg";
import { ReactComponent as IconLeft } from "./assets/ic_left.svg";
import { ReactComponent as IconRight } from "./assets/ic_right.svg";

import styles from "./carriage.module.sass";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { useEffect, useRef, useState } from "react";

type CarriageProps = {
  data?: MonitorDataItem[];
  onServiceClick: (hostId: number, type: MonitorDataTypes) => void;
};

type ItemType = {
  type: MonitorDataTypes;
  typeName: string;
  hostId: number;
  hostName: string;
  name: string;
  ip: string;
  status: AllowedStatuses;
};

const mapDataMonitoring = (data: MonitorDataItem[]) => {
  const items: ItemType[] = [];
  const groupItems: ItemType[][] = [[]];

  data.forEach((el) => {
    el?.monitoringData?.forEach((elMonitor) =>
      items.push({
        type: el?.type,
        typeName: el?.typeName,
        hostName: elMonitor?.hostName,
        name: elMonitor?.name,
        ip: elMonitor?.ip,
        status: elMonitor?.status,
        hostId: elMonitor.hostId,
      })
    );
  });

  let countItems = 0;
  let currentGroupIndex = 0;
  for (let i = 0; i < items.length; i++) {
    const element = items[i];
    if (countItems < 2) {
      groupItems[currentGroupIndex].push(element);
      countItems++;
    } else if (countItems === 2) {
      groupItems.push([element]);
      currentGroupIndex++;
      countItems = 1;
    }
  }

  return groupItems;
};

const getSeviceIcon = (type: MonitorDataTypes) => {
  const icons = {
    [MonitorDataTypes.INFORMATION]: () => <></>,
    [MonitorDataTypes.KIM]: () => <IconIm />,
    [MonitorDataTypes.WI_FI]: () => <IconWifi />,
    [MonitorDataTypes.SVNR]: () => <IconVideo />,
    [MonitorDataTypes.SKDU]: () => <IconSkdy />,
  };

  return icons?.[type]();
};

const SERVICES_WIDTH = 148;

export const Carriage = ({ data, onServiceClick }: CarriageProps) => {
  const swiperRef = useRef<SwiperType>();
  const [progress, setProgress] = useState<{
    isBeginning: boolean;
    isEnd: boolean;
  }>({
    isBeginning: true,
    isEnd: false,
  });
  const refWrap = useRef<HTMLDivElement>(null);
  const [maxServices, setMaxServices] = useState<number>(13);

  useEffect(() => {
    refWrap.current &&
      setMaxServices(refWrap.current.offsetWidth / SERVICES_WIDTH);
    const onSetMaxCarriages = () => {
      refWrap.current &&
        setMaxServices(refWrap.current.offsetWidth / SERVICES_WIDTH);
    };
    window.addEventListener("resize", onSetMaxCarriages);
    return () => window.removeEventListener("resize", onSetMaxCarriages);
  }, []);

  const items = data && mapDataMonitoring(data);

  useEffect(() => {
    setProgress({
      isBeginning: true,
      isEnd: false,
    });
  }, [data]);

  return (
    <div className={styles?.carriage}>
      <div className={styles?.carriageDoorWrap}>
        <div className={styles?.carriageDoor}>
          <div className={styles?.carriageDoorMirror}></div>
        </div>
      </div>
      <div className={styles?.carriageServicesWrap} ref={refWrap}>
        {data && data?.length > 0 ? (
          <>
            <Swiper
              spaceBetween={16}
              slidesPerView="auto"
              modules={[FreeMode, Navigation]}
              onBeforeInit={(swiper: SwiperType) => {
                swiperRef.current = swiper;
              }}
              onReachBeginning={() => {
                setProgress((prev) => ({
                  isBeginning: true,
                  isEnd: prev?.isEnd,
                }));
              }}
              onReachEnd={() => {
                setProgress((prev) => ({
                  isBeginning: prev?.isBeginning,
                  isEnd: true,
                }));
              }}
              onSlideChange={(swiper: SwiperType) => {
                setProgress({
                  isBeginning: swiper?.isBeginning,
                  isEnd: swiper?.isEnd,
                });
              }}
            >
              {items?.map((item, idx) => (
                <SwiperSlide key={idx}>
                  <div className={styles.carriageServiceItemSlide}>
                    {item?.map((el) => (
                      <div
                        className={classNames(
                          styles?.carriageServiceItem,
                          el.status === AllowedStatuses.UNKNOWN &&
                            styles.carriageServiceItemDEFAULT,
                          el.status === AllowedStatuses.ERROR &&
                            styles.carriageServiceItemERROR,
                          el.status === AllowedStatuses.OK &&
                            styles.carriageServiceItemSUCCESS
                        )}
                        onClick={() => onServiceClick(el?.hostId, el?.type)}
                        key={el?.hostId}
                      >
                        <div className={styles?.carriageServiceItemHeader}>
                          <div
                            className={styles?.carriageServiceItemHeaderTitle}
                          >
                            {el?.typeName}
                          </div>
                          <div
                            className={
                              styles?.carriageServiceItemHeaderSubTitle
                            }
                          >
                            {el?.name}
                          </div>
                          <div
                            className={styles?.carriageServiceItemHeaderIcon}
                          >
                            {el?.type ? getSeviceIcon(el?.type) : undefined}
                          </div>
                        </div>
                        <div className={styles?.carriageServiceItemContent}>
                          <div
                            className={styles?.carriageServiceItemContentText}
                          >
                            {/* {el?.hostName} */}
                          </div>
                          <div
                            className={
                              styles?.carriageServiceItemContentCaption
                            }
                          >
                            {el?.ip}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {!progress?.isBeginning && (
              <div
                className={styles.swiperPrev}
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <IconLeft />
              </div>
            )}
            {!progress?.isEnd && items && items.length > maxServices && (
              <div
                className={styles.swiperNext}
                onClick={() => swiperRef.current?.slideNext()}
              >
                <IconRight />
              </div>
            )}
          </>
        ) : (
          <div className={styles.noData}>
            Данные по узлам мониторинга отсутствуют
          </div>
        )}
      </div>
      <div className={styles?.carriageDoorWrap}>
        <div className={styles?.carriageDoor}>
          <div className={styles?.carriageDoorMirror}></div>
        </div>
      </div>
    </div>
  );
};
