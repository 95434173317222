import { api } from "../../../api";
import { TimelineRequest, TimelineResponse } from "../@types";
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const getTimeline = async (
  params: TimelineRequest
): Promise<TimelineResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  if (!params?.tripId) return;

  const resp = await api.get(`/fpk/trips/${params?.tripId}/timeline`, {
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
};
