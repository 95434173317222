import { EnumLiteralsOf } from "../../../@types";
import { AllowedStatuses, CarProps } from "../../FilialDetail/@types";

export type CarsByTripRequest = {
  tripId?: string;
};

export type CarsByTripResponse = {
  id: number;
  name: string;
  route: string;
  cars: CarProps[];
};

export type CarByTripRequest = {
  tripId?: string;
  carId?: string;
};

export type CarByTripResponse = {
  number: string;
  info: {
    departureDateTime: string;
    maintenance: string;
    maintenanceDateTime: string;
    maintenancePlace: string;
    engineer: string;
    phone: string;
  };
  monitoringDataTypes: MonitorDataItem[];
};

export const MonitorDataTypes = Object.freeze({
  INFORMATION: "INFORMATION",
  WI_FI: "WI_FI",
  KIM: "KIM",
  SKDU: "SKDU",
  SVNR: "SVNR",
});

export const MonitorDataTypesArray = Object.keys(MonitorDataTypes);

export type MonitorDataTypes = EnumLiteralsOf<typeof MonitorDataTypes>;

export type MonitorDataItem = {
  type: MonitorDataTypes;
  typeName: string;
  monitoringData: [
    {
      hostId: number;
      hostName: string;
      name: string;
      ip: string;
      status: AllowedStatuses;
    }
  ];
};

export type RouteByTripRequest = {
  tripId?: string;
};

export type RouteByTripResponse = {
  route: string;
  name: string;
  tripDuration: string;
  departureStation: Station;
  arrivalStation: Station;
  intermediateStations: Station[];
};

export type TimelineRequest = {
  tripId?: string;
};

export type TimelineResponse = {
  transits: Transit[];
};

export type Transit = {
  day: number;
  enabled: boolean;
  fromStation: Station;
  toStation: Station;
  duration: string;
};

export type Station = {
  stationName: string;
  depotName: string;
  departureTime: string;
  departureDate: string;
  arrivalTime: string;
  arrivalDate: string;
  stopDuration: string;
  day: number;
};

export type CarDataRequest = {
  tripId?: string;
  carId?: string;
  hostId?: string;
  page?: number;
  beginDateTime?: string;
  endDateTime?: string;
};

export type Threshold = {
  name: string;
  value: number;
};

export type DataItem = {
  id: number;
  name: string;
  description: string;
  items: [
    {
      id: number;
      name: string;
      color: string;
      data: [
        {
          dateTime: string;
          value: number;
        }
      ];
      thresholds: Threshold[];
    }
  ];
};

export type CarDataResponse = {
  data: DataItem[];
  total: number;
  page: number;
  pageSize: number;
};

export type CarDataProblemsRequest = {
  tripId?: string;
  carId?: string;
  hostId?: string;
  page?: number;
};

export const SeverityDataTypes = Object.freeze({
  HIGH: "HIGH",
  NOT_CLASSIFIED: " NOT_CLASSIFIED",
  INFO: "INFO",
  WARNING: "WARNING",
  AVERAGE: "AVERAGE",
  DISASTER: "DISASTER",
});

export type SeverityDataTypes = EnumLiteralsOf<typeof SeverityDataTypes>;

export type CarDataProblemItem = {
  dateTime: string;
  description: string;
  severity: SeverityDataTypes;
};

export type CarDataProblemsResponse = {
  data: CarDataProblemItem[];
  total: number;
  page: number;
  pageSize: number;
};

export enum EnumTabs {
  "information",
  "wifi",
  "im",
  "skdy",
  "svnr",
}

export type GetReportRequest = {
  graphId?: number;
  graphItemIds?: number[];
  beginDateTime?: string;
  endDateTime?: string;
  tripId?: string;
  hostId?: string;
  carriageNumber?: string;
};
