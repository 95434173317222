import moment from "moment";
import {
  TCurrentMonth,
  TDisableDays,
  TMonthPosition,
  TParsedDay,
} from "./types";
import "moment/locale/ru";
import "moment/locale/es";
import "moment/locale/en-au";

export const getPeriodStringFormat = (
  startDate?: Date | null,
  endDate?: Date | null,
  language = "ru",
  format = "DD.MM",
  allowSameDay?: boolean
) => {
  if (startDate && endDate) {
    const from = moment(startDate);
    const to = moment(endDate);
    if (from.isSame(to, "day") && !allowSameDay) {
      return from.locale(language).format(format);
    }
    return `${from.locale(language).format(format)} — ${to
      .locale(language)
      .format(format)}`;
  }
  return null;
};

export const dateToCurrentMonth = (date: Date): TCurrentMonth => {
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
  };
};

export const isCurrentDay = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const getDaysOfMonth = ({
  currentMonth,
  monthPosition,
}: {
  currentMonth: TCurrentMonth;
  monthPosition: TMonthPosition;
}): TParsedDay[] => {
  const daysInMonth = new Date(
    currentMonth.year,
    currentMonth.month + 1,
    0
  ).getDate();

  const daysArray: TParsedDay[] = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = new Date(currentMonth.year, currentMonth.month, day);
    const { key, dateDay } = getDayKey(currentDate);

    daysArray.push({
      day: currentDate,
      key,
      monthPosition,
      dateDay,
    });
  }

  return daysArray;
};

export const getVisibleDays = (currentMonth: TCurrentMonth): TParsedDay[] => {
  const currentDays = getDaysOfMonth({
    currentMonth,
    monthPosition: "currentMonth",
  });
  const firstDayOfWeek = currentDays[0].day?.getDay() || 0;

  // Получаем дни предыдущего месяца
  const prevMonth = currentMonth.month === 0 ? 11 : currentMonth.month - 1;
  const prevYear =
    currentMonth.month === 0 ? currentMonth.year - 1 : currentMonth.year;
  const prevMonthDays =
    firstDayOfWeek !== 1
      ? getDaysOfMonth({
          currentMonth: {
            year: prevYear,
            month: prevMonth,
          },
          monthPosition: "prevMonth",
        })
      : [];
  const prevDaysCount = (firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1) % 7;

  const prevDays = prevMonthDays.slice(-prevDaysCount);

  // Получаем дни следующего месяца
  const nextMonth = currentMonth.month === 11 ? 0 : currentMonth.month + 1;
  const nextYear =
    currentMonth.month === 11 ? currentMonth.year + 1 : currentMonth.year;
  const nextMonthDays = getDaysOfMonth({
    currentMonth: {
      year: nextYear,
      month: nextMonth,
    },
    monthPosition: "nextMonth",
  });
  const nextDaysCount = 42 - currentDays.length - prevDaysCount;

  const nextDays = nextMonthDays.slice(0, nextDaysCount);

  return [...prevDays, ...currentDays, ...nextDays];
};

const getDayKey = (date: Date) => {
  const day = date.getDate();
  return { key: moment(date).format("YYYY-MM-DD"), dateDay: day };
};

export const getFormattedMonth = ({
  language,
  value,
}: {
  language: string;
  value: TCurrentMonth;
}) => {
  const monthFormat = new Intl.DateTimeFormat(language, { month: "long" });

  const monthParts = monthFormat.formatToParts(
    new Date(value.year, value.month, 1)
  );

  const month = monthParts
    .filter((part) => part.type === "month" || part.type === "literal")
    .map((part) => part.value)
    .join("");

  const year = value.year;

  return `${month.charAt(0).toUpperCase()}${month.slice(1)} ${year}`;
};

export const getWeekdays = (language: string) => {
  const weekdays = [];
  const dayNameFormat = new Intl.DateTimeFormat(language, { weekday: "short" });

  for (let i = 2; i <= 7; i++) {
    const dayIndex = i % 7 === 0 ? 7 : i % 7;
    const dayName = dayNameFormat.format(new Date(2023, 0, dayIndex));

    weekdays.push(`${dayName.charAt(0).toUpperCase()}${dayName.slice(1)}`);
  }

  const monday = dayNameFormat.format(new Date(2023, 0, 1));
  weekdays.push(`${monday.charAt(0).toUpperCase()}${monday.slice(1)}`);

  return weekdays;
};

export const compareOrders = ({
  from,
  to,
}: {
  from: Date | null;
  to: Date | null;
}): { from: Date | null; to: Date | null } => {
  if (from && to) {
    const fromDate = moment(from);
    const toDate = moment(to);

    if (toDate.isBefore(fromDate)) {
      return { from: toDate.toDate(), to: fromDate.toDate() };
    }
  }

  return { from, to };
};

export const getRangeOfDates = (
  value: { from: Date | null; to: Date | null } | null | undefined
): string[] => {
  if (!value?.from || !value.to) {
    return [];
  }

  const { from, to } = value;

  const fromDate = moment(from);
  const toDate = moment(to);

  if (toDate.isBefore(fromDate)) {
    return [];
  }

  const dates: string[] = [];

  const currentDate = fromDate.clone().add(1, "day");
  while (currentDate.isBefore(toDate, "day")) {
    dates.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return dates;
};

export const getIsDayDisabled = ({
  before,
  after,
  allowedDays,
  formattedDay,
  date,
}: {
  before: Date | null;
  after: Date | null;
  allowedDays: Date[] | null;
  formattedDay: string;
  date: Date;
}) => {
  if (Array.isArray(allowedDays)) {
    const allowedDaysFormatted = new Set(
      allowedDays.map((allowedDay) => moment(allowedDay).format("YYYY-MM-DD"))
    );

    return !allowedDaysFormatted.has(formattedDay);
  }

  if (before) {
    if (moment(date).isBefore(before, "day")) {
      return true;
    }
  }

  if (after) {
    if (moment(date).isAfter(after, "day")) {
      return true;
    }
  }

  return false;
};

export const getDisabledDayByLimit = ({
  selectedDate,
  limitDays,
  disabledDays,
}: {
  selectedDate: Date | null;
  limitDays: number;
  disabledDays: TDisableDays;
}): TDisableDays => {
  if (!selectedDate) {
    return disabledDays;
  }

  const momentSelectedDate = moment(selectedDate);

  let beforeDate = momentSelectedDate
    .clone()
    .subtract(limitDays, "days")
    .toDate();

  beforeDate = moment
    .max(moment(beforeDate), moment(moment().startOf("day")))
    .toDate();

  const afterDate = disabledDays.after
    ? moment
        .min(
          moment(disabledDays.after),
          momentSelectedDate.clone().add(limitDays, "days")
        )
        .toDate()
    : momentSelectedDate.clone().add(limitDays, "days").toDate();

  return { before: beforeDate, after: afterDate };
};

export const isBefore = (date: Date, compareTo: Date | null): boolean => {
  if (!compareTo) {
    return false;
  }
  return moment(date).isBefore(compareTo);
};

export const isAfter = (date: Date, compareTo: Date | null): boolean => {
  if (!compareTo) {
    return false;
  }
  return moment(date).isAfter(compareTo);
};

export const isAllowByDisabledRange = (
  day: Date,
  disabledDays: TDisableDays
): boolean => {
  if (!disabledDays.before || !disabledDays.after) {
    return true;
  }

  return day >= disabledDays.before && day <= disabledDays.after;
};

/** Получаем вторую зазрешенную дату, когда они совпадают */
export const getSomeSecondDate = ({
  disabledDays,
  someDay,
}: {
  disabledDays: TDisableDays;
  someDay: Date;
}): Date => {
  const nextDay = moment(someDay).add(1, "day").toDate();
  const prevDay = moment(someDay).subtract(1, "day").toDate();

  if (isAllowByDisabledRange(nextDay, disabledDays)) {
    return nextDay;
  } else if (isAllowByDisabledRange(prevDay, disabledDays)) {
    return prevDay;
  } else {
    return someDay;
  }
};
