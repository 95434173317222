import { useQuery } from "@tanstack/react-query";
import {
  COOKIE_AUTH_TOKEN,
  COOKIE_REFRESH_AUTH_TOKEN,
  routesVariables,
} from "../../constants";
import Cookies from "js-cookie";
import { user } from "./api/user";
import { Menu } from "../Menu";
import { useState } from "react";
import { ReactComponent as IconClose } from "./assets/ic_close.svg";
import { ReactComponent as IconLogout } from "./assets/ic_logout.svg";
import { ReactComponent as IconLogo } from "./assets/ic_logo.svg";
import { logout } from "./api/logout";
import { useNavigate } from "react-router-dom";

import styles from "./header.module.sass";

export const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  const { data: userInfo } = useQuery({
    queryKey: ["user"],
    queryFn: () => user(),
    refetchOnWindowFocus: false,
    enabled: !!token,
  });

  const handleLogout = async () => {
    try {
      await logout();
      Cookies.remove(COOKIE_AUTH_TOKEN);
      Cookies.remove(COOKIE_REFRESH_AUTH_TOKEN);
      navigate(routesVariables.login);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.logo}>
          <IconLogo />
        </div>
        {userInfo && token && (
          <div className={styles.avatar} onClick={handleClick}>
            {userInfo?.firstName?.[0]}
            {userInfo?.lastName?.[0]}
          </div>
        )}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          MenuListProps={{
            sx: {
              padding: 0,
            },
          }}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: "284px",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                boxShadow: "0px 4px 16px 0px rgba(46, 64, 87, 0.24)",
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className={styles.headerMenu}>
            <div className={styles.headerMenuContent}>
              <div className={styles.headerMenuContentText}>
                <div className={styles.headerMenuContentName}>
                  {userInfo?.firstName} {userInfo?.lastName}
                </div>
                <div className={styles.headerMenuContentSocial}>
                  {userInfo?.phone}
                </div>
              </div>
              <div
                className={styles.headerMenuContentClose}
                onClick={handleClose}
              >
                <IconClose />
              </div>
            </div>
            <div className={styles.headerMenuLogout} onClick={handleLogout}>
              <div className={styles.headerMenuLogoutIcon}>
                <IconLogout />
              </div>
              <div className={styles.headerMenuLogoutText}>Выйти</div>
            </div>
          </div>
        </Menu>
      </div>
    </div>
  );
};
