import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

type FilterValue =
  | string
  | number
  | undefined
  | Date
  | (string | number | Date)[];
export type FiltersFromUrlQueryParams = { [key: string]: FilterValue };

const transformFromSearchParamsToFilters = (
  searchParams: URLSearchParams,
  alowedParams: string[]
) => {
  return Array.from(searchParams?.entries()).reduce((acc, [key, value]) => {
    if (alowedParams.includes(key)) {
      if (acc[key]) {
        acc[key] = Array.isArray(acc[key])
          ? //@ts-ignore
            [...acc[key], value]
          : [acc[key], value];
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {} as FiltersFromUrlQueryParams);
};

const useFiltersFromUrlQueryParams = (allowedParams: string[] = []) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const pathname = location?.pathname;
  const navigate = useNavigate();
  const [filtersIsInitialized, setFiltersIsInitialized] = useState(false);

  const [filters, setFilters] = useState<FiltersFromUrlQueryParams>();

  useEffect(() => {
    const initialFiltersFromQueryString = transformFromSearchParamsToFilters(
      searchParams,
      allowedParams
    );
    setFilters(initialFiltersFromQueryString);
    setFiltersIsInitialized(true);
  }, []);

  const updateFilters = useCallback(
    (filters: FiltersFromUrlQueryParams) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, ...filters };

        updateUrlFromFilters(newFilters);
        return newFilters;
      });
    },
    [allowedParams, searchParams.toString()]
  );

  const handleChangeFilter = useCallback(
    (...names: string[]) =>
      (...values: FilterValue[]) => {
        const filters = names.reduce((acc, name, index) => {
          acc[name] = values?.[index];
          return acc;
        }, {} as FiltersFromUrlQueryParams);
        updateFilters(filters);
      },
    [updateFilters]
  );

  const updateUrlFromFilters = (filters: FiltersFromUrlQueryParams) => {
    Object.entries(filters).forEach(([filterName, filterValue]) => {
      if (!allowedParams.includes(filterName)) return;

      if (filterValue == null || filterValue === "") {
        searchParams.delete(filterName);
        return;
      }

      if (Array.isArray(filterValue)) {
        searchParams.delete(filterName);
        filterValue.forEach((item) =>
          searchParams.append(filterName, `${item}`)
        );
        return;
      }

      searchParams.set(filterName, `${filterValue}`);
    });

    return navigate(`${pathname}?${searchParams.toString()}`);
  };

  return {
    filters,
    updateFilters,
    handleChangeFilter,
    filtersIsInitialized,
    setFiltersIsInitialized,
  };
};

export default useFiltersFromUrlQueryParams;
