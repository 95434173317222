import classNames from "classnames";
import { FC, ReactNode, useRef, WheelEvent } from "react";

import styles from "./tabs.module.sass";

export type TabsProps = {
  children?: ReactNode;
  position?: "center";
  tabsClassName?: string;
};

export const Tabs: FC<TabsProps> = ({ children, tabsClassName, position }) => {
  const boxRef = useRef<HTMLDivElement>(null);

  const handleWheel = (e: WheelEvent<HTMLElement>): void => {
    const container = boxRef.current;
    if (container) {
      const containerScrollPosition = container.scrollLeft;
      container.scrollTo({
        top: 0,
        left: containerScrollPosition + e.deltaY / 5,
      });
    }
  };

  return (
    <div
      className={classNames(
        styles.wrap,
        tabsClassName,
        position && styles.center
      )}
      onWheel={handleWheel}
      ref={boxRef}
    >
      {children}
    </div>
  );
};
