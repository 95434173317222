import { BreadcrumbItem, Breadcrumbs } from "../../components/Breadcrumbs";
import { Layout } from "../../layouts/Layout";
import {
  ALLOWED_FILTERS,
  COOKIE_AUTH_TOKEN,
  routesVariables,
} from "../../constants";
import { generateLinkMask } from "utils";
import { useLocation, useParams } from "react-router-dom";
import useFiltersFromUrlQueryParams from "../../hooks/useFiltersFromUrlQueryParams";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { getFilialDetail } from "./api/getFilialDetail";
import { Filters } from "../../components/Filters";
import { TrainCard } from "./components/TrainCard";
import { Button } from "../../components/Button";
import { useMemo } from "react";
import { FilialDetailResponse, TrainCardProps } from "./@types";
import Cookies from "js-cookie";

import styles from "./page.module.sass";

export default function FilialDetail() {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const { filters, filtersIsInitialized, updateFilters } =
    useFiltersFromUrlQueryParams(
      ALLOWED_FILTERS as unknown as string[]
    ) as unknown as {
      filters: Record<(typeof ALLOWED_FILTERS)[number], string>;
      updateFilters: (...values: any[]) => void;
      filtersIsInitialized: boolean;
    };

  const { data, refetch, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<FilialDetailResponse | undefined>({
      queryKey: ["filialDetail", token],
      queryFn: ({ pageParam }) =>
        filtersIsInitialized
          ? getFilialDetail({
              ...filters,
              branch: id,
              page: pageParam as number,
            })
          : undefined,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage) return;
        return lastPage?.total !==
          allPages?.reduce((acc, el) => {
            if (!el?.data) return acc;
            return acc + el?.data?.length;
          }, 0)
          ? lastPage?.page + 1
          : undefined;
      },
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      enabled: false,
    });

  const trains = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return [...acc, ...(page?.data ?? [])];
    }, [] as TrainCardProps[]);
  }, [data]);

  const filialName = data?.pages?.[0]?.branchName ?? "";

  const breadcrumbsItems = [
    {
      path: `${routesVariables.filials}${location.search}`,
      title: "Филиалы",
    },
    {
      path: `${generateLinkMask(routesVariables.filialDetail, { id: 1 })}${
        location.search
      }`,
      title: filialName,
      current: true,
    },
  ];

  return (
    <Layout>
      <div className={styles.pageWrapper}>
        <Breadcrumbs>
          {breadcrumbsItems?.map((el, key) => (
            <BreadcrumbItem
              current={el.current}
              key={key}
              breadCrumbPathName={el.path}
            >
              {el.title}
            </BreadcrumbItem>
          ))}
        </Breadcrumbs>
        <div className={styles.title}>{filialName}</div>
        <div className={styles.filtersWrap}>
          <Filters
            disabledDate
            disabledBranches
            onSubmit={() => refetch()}
            filters={filters}
            updateFilters={updateFilters}
            filtersIsInitialized={filtersIsInitialized}
          />
        </div>
        <div className={styles.trainsWrap}>
          {trains &&
            trains.map((train) => <TrainCard key={train?.id} {...train} />)}
        </div>
        {hasNextPage && (
          <div className={styles.showMore}>
            <Button
              disabled={isFetching}
              variant="contained"
              sx={{
                background: "rgba(61, 92, 221, 1)",
                height: "40px",
                textTransform: "capitalize",
                fontFamily: "Raleway",
                fontSize: "14px",
                color: "#fff",
                boxShadow: "0px 0px 8px 0px rgba(61, 92, 221, 0.08)",
                borderRadius: "4px",
              }}
              onClick={() => fetchNextPage()}
            >
              Показать еще
            </Button>
          </div>
        )}
      </div>
    </Layout>
  );
}
