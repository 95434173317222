import moment from "moment";
import { ReactComponent as IconStep } from "./assets/ic_step.svg";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { generateLinkMask } from "../../../../utils";
import { routesVariables } from "../../../../constants";
import { TrainCardProps } from "../../../FilialDetail/@types";

import styles from "./sideBarItem.module.sass";

export const SideBarItem = (item: TrainCardProps) => {
  const {
    route,
    serviceLevel,
    name,
    arrivalDateTime,
    departureDateTime,
    id,
    cars,
  } = item;

  const navigate = useNavigate();
  const location = useLocation();

  const onItemClick = () => {
    const search = new URLSearchParams(location.search);
    search.set("tripId", `${id}`);
    const pathname = generateLinkMask(routesVariables.carriageDetail, {
      id: cars?.[0]?.number,
    });

    navigate(`${pathname}?${search.toString()}`);
  };

  const isActive = () => {
    const search = new URLSearchParams(location.search);

    return search.get("tripId") === `${id}`;
  };

  return (
    <div
      className={classNames(
        styles.sideBarItem,
        isActive() && styles.sideBarItemActive
      )}
      onClick={onItemClick}
    >
      <div className={styles.sideBarItemHeader}>
        <div className={styles.sideBarItemHeaderTitle}>
          <div className={styles.sideBarItemHeaderTitleNum}>{route}</div>
          {serviceLevel !== undefined && (
            <div
              className={classNames(
                styles.sideBarItemHeaderTitleIcon,
                serviceLevel < 2 && styles.sideBarItemHeaderTitleIconError,
                serviceLevel > 2 &&
                  serviceLevel <= 4 &&
                  styles.sideBarItemHeaderTitleIconDefault,
                serviceLevel >= 5 && styles.sideBarItemHeaderTitleIconSuccess
              )}
            >
              <IconStep /> {serviceLevel}
            </div>
          )}
        </div>
        <div className={styles.sideBarItemHeaderCaption}>{name}</div>
      </div>
      <div className={styles.sideBarItemContent}>
        <div className={styles.sideBarItemContentItem}>
          <div className={styles.sideBarItemContentItemCaption}>
            Отправление
          </div>
          <div className={styles.sideBarItemContentItemTitle}>
            {moment(departureDateTime).format("HH:mm, DD.MM.YYYY")}
          </div>
        </div>
        <div className={styles.sideBarItemContentItem}>
          <div className={styles.sideBarItemContentItemCaption}>Прибытие</div>
          <div className={styles.sideBarItemContentItemTitle}>
            {moment(arrivalDateTime).format("HH:mm, DD.MM.YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
};
