import queryString from "query-string";
import { api } from "../../../api";
import { FilialsResponse, FilialsRequest } from "../@types";
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const getFilials = async (
  params: FilialsRequest
): Promise<FilialsResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  if (!params?.beginDate && !params.endDate) return;

  const resp = await api.get(`/fpk/branches`, {
    params: {
      ...params,
    },
    headers: {
      Authorization: token,
    },
    paramsSerializer: (params) => {
      return queryString.stringify(params);
    },
  });

  return resp.data;
};
