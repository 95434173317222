import {
  CarByTripResponse,
  MonitorDataTypes,
  TimelineResponse,
} from "../../@types";
import { getSubTabs } from "../../utils/getSubTabs";
import classNames from "classnames";
import { TableWrapper } from "../TableWrapper";
import { ChartWrapper } from "../ChartWrapper";
import { TrackSlider } from "../TrackSlider";
import { useDetectSticky } from "../../../../hooks/useDetectSticky";
import { useRef } from "react";

import styles from "./tabPanelItem.module.sass";

type TabPanelItemProps = {
  carByTrip?: CarByTripResponse;
  monitorDataType: MonitorDataTypes;
  hostId?: string;
  onHostIdClick: (hostId: number, type?: MonitorDataTypes) => void;
  timeline?: TimelineResponse;
  selectedIntreval?: {
    startDate: string;
    endDate: string;
  };
  setSelectedIntreval: (data?: { startDate: string; endDate: string }) => void;
};

export const TabPanelItem = (props: TabPanelItemProps) => {
  const {
    carByTrip,
    monitorDataType,
    hostId,
    onHostIdClick,
    timeline,
    selectedIntreval,
    setSelectedIntreval,
  } = props;
  const refTrackSlider = useRef<HTMLDivElement>(null);

  const { isSticky } = useDetectSticky(refTrackSlider);

  return (
    <>
      <div className={styles.tabSubTabs}>
        {getSubTabs(monitorDataType, carByTrip?.monitoringDataTypes).map(
          (el) => (
            <div
              className={classNames(
                styles.tabSubTabItem,
                hostId === `${el.hostId}` && styles.tabSubTabItemActive
              )}
              onClick={() => onHostIdClick(el.hostId)}
              key={el.hostId}
            >
              {el.name}
            </div>
          )
        )}
      </div>
      <div className={styles.tabTable}>
        <TableWrapper />
      </div>
      <div
        className={classNames(
          styles.tabTrackSlider,
          isSticky && styles.tabTrackSliderShadow
        )}
        ref={refTrackSlider}
      >
        <TrackSlider
          timeline={timeline}
          selectedInterval={selectedIntreval}
          setSelectedIntreval={setSelectedIntreval}
        />
      </div>
      <div className={styles.tabChart}>
        <ChartWrapper dates={selectedIntreval} />
      </div>
    </>
  );
};
