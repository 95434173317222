import { CarProps } from "../../../FilialDetail/@types";
import { Train } from "../../../../components/Train";
import { useLocation, useNavigate } from "react-router-dom";
import { generateLinkMask } from "../../../../utils";
import { routesVariables } from "../../../../constants";

import styles from "./trainCard.module.sass";

type TrainCardProps = {
  route: string;
  name: string;
  cars: CarProps[];
  onHeaderClick: () => void;
};

export const TrainCard = ({
  name,
  route,
  cars,
  onHeaderClick,
}: TrainCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onCarClick = (carId: string) => {
    navigate(
      `${generateLinkMask(routesVariables.carriageDetail, {
        id: carId,
      })}${location.search}`
    );
  };
  return (
    <div className={styles.trainWrap}>
      <div className={styles.trainHeader}>
        <div className={styles.trainHeaderNum}>{route}</div>
        <div className={styles.trainHeaderTitle} onClick={onHeaderClick}>
          {name}
        </div>
      </div>
      <div className={styles.trainContent}>
        <Train cars={cars} onCarClick={onCarClick} />
      </div>
    </div>
  );
};
