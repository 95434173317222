import moment from "moment";
import { TrainCardProps } from "../../@types";
import { Train } from "../../../../components/Train";
import { useLocation, useNavigate } from "react-router-dom";
import { generateLinkMask } from "../../../../utils";
import { routesVariables } from "../../../../constants";
import classNames from "classnames";

import styles from "./trainCard.module.sass";

export const TrainCard = (props: TrainCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    departureDateTime,
    arrivalDateTime,
    route,
    name,
    serviceLevel,
    cars,
    id: tripId,
  } = props;

  const onCarClick = (carId: string) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set("tripId", `${tripId}`);

    navigate(
      `${generateLinkMask(routesVariables.carriageDetail, {
        id: carId,
      })}?${searchParams.toString()}`
    );
  };

  return (
    <div className={styles.trainWrap}>
      <div className={styles.trainHeader}>
        <div className={styles.trainNumWrap}>
          <div className={styles.trainNum}>{route}</div>
          <div className={styles.trainTitle}>{name}</div>
        </div>
        <div className={styles.trainArrivalAndDebarture}>
          <div className={styles.trainArrivalAndDebartureCaption}>
            Отправление:
          </div>
          <div className={styles.trainArrivalAndDebartureTitle}>
            {moment(departureDateTime).format("HH:mm, DD.MM.YYYY")}
          </div>
        </div>
        <div className={styles.trainArrivalAndDebarture}>
          <div className={styles.trainArrivalAndDebartureCaption}>
            Прибытие:
          </div>
          <div className={styles.trainArrivalAndDebartureTitle}>
            {moment(arrivalDateTime).format("HH:mm, DD.MM.YYYY")}
          </div>
        </div>
        <div className={styles.trainServices}>
          {serviceLevel !== undefined && (
            <>
              <div className={styles.trainServicesCaption}>
                Показатель уровня сервиса:
              </div>
              <div
                className={classNames(
                  styles.trainServicesNum,
                  serviceLevel < 2 && styles.trainServicesNumError,
                  serviceLevel > 2 &&
                    serviceLevel <= 4 &&
                    styles.trainServicesNumDefault,
                  serviceLevel >= 5 && styles.trainServicesNumSuccess
                )}
              >
                {serviceLevel}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.trainContent}>
        <Train cars={cars} onCarClick={onCarClick} />
      </div>
    </div>
  );
};
