import React from "react";
import { ReactComponent as IconArrow } from "../assets/ic_arrow.svg";
import classnames from "classnames";
import { BreadcrumbItemProps } from "../types";

import styles from "../breadcrumb.module.sass";

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = (props) => {
  const { className, disabled, current, breadCrumbPathName, children } = props;

  const href = current ? "" : breadCrumbPathName;

  return (
    <li className={classnames(styles.listItem, className)}>
      <div className={styles.arrow}>
        <IconArrow />
      </div>
      <a
        className={classnames(
          styles.item,
          current && styles.current,
          disabled && styles.disabled
        )}
        href={href}
      >
        {children}
      </a>
    </li>
  );
};
