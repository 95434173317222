import classNames from "classnames";
import { FC, ReactNode } from "react";

export type TabPanelProps = {
  value: number;
  index: number;
  className?: string;
  children?: ReactNode;
};

export const TabPanel: FC<TabPanelProps> = ({
  value,
  index,
  className,
  children,
}) => {
  const isView = value === index;

  return (
    <div hidden={!isView} className={classNames(className)}>
      {isView && children}
    </div>
  );
};
