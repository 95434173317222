import { ReactQueryProvider } from "layouts/reactQueryProvider";
import { Routes } from "./containers";

function App() {
  return (
    <ReactQueryProvider>
      <Routes />
    </ReactQueryProvider>
  );
}

export default App;
