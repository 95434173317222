import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";
import { Swiper as SwiperType } from "swiper";
import { useRef, useState, useEffect } from "react";
import { ReactComponent as IconLeft } from "./assets/ic_left.svg";
import { ReactComponent as IconRight } from "./assets/ic_right.svg";
import { EnumLiteralsOf } from "../../@types";
import { useParams } from "react-router-dom";

import styles from "./train.module.sass";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

type IconProps = {
  className?: string;
};

const IconFirstCarriage = ({ className }: IconProps) => (
  <svg
    width="106"
    height="54"
    viewBox="0 0 106 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      className="icFill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 41C1 18.9086 18.9086 1 41 1H101C103.209 1 105 2.79086 105 5V45C105 47.2091 103.209 49 101 49H98.0018C97.0223 51.349 94.7039 53 92 53C89.2961 53 86.9777 51.349 85.9982 49H80.1268C79.1473 51.349 76.8289 53 74.125 53C71.4211 53 69.1027 51.349 68.1232 49H41.1268C40.1473 51.349 37.8289 53 35.125 53C32.421 53 30.1027 51.349 29.1232 49H23.2518C22.2723 51.349 19.954 53 17.25 53C14.5461 53 12.2277 51.349 11.2482 49H5C2.79086 49 1 47.2091 1 45V41Z"
    />
    <path
      className="icBorder"
      d="M98.0018 49V48H97.3354L97.0789 48.6151L98.0018 49ZM85.9982 49L86.9211 48.6151L86.6646 48H85.9982V49ZM80.1268 49V48H79.4604L79.2039 48.6151L80.1268 49ZM68.1232 49L69.0461 48.6151L68.7896 48H68.1232V49ZM41.1268 49V48H40.4604L40.2039 48.6151L41.1268 49ZM29.1232 49L30.0461 48.6151L29.7896 48H29.1232V49ZM23.2518 49V48H22.5854L22.3289 48.6151L23.2518 49ZM11.2482 49L12.1711 48.6151L11.9146 48H11.2482V49ZM41 0C18.3563 0 0 18.3563 0 41H2C2 19.4609 19.4609 2 41 2V0ZM101 0H41V2H101V0ZM106 5C106 2.23858 103.761 0 101 0V2C102.657 2 104 3.34315 104 5H106ZM106 45V5H104V45H106ZM101 50C103.761 50 106 47.7614 106 45H104C104 46.6569 102.657 48 101 48V50ZM98.0018 50H101V48H98.0018V50ZM97.0789 48.6151C96.2492 50.6046 94.2864 52 92 52V54C95.1215 54 97.7953 52.0933 98.9248 49.3849L97.0789 48.6151ZM92 52C89.7136 52 87.7508 50.6046 86.9211 48.6151L85.0752 49.3849C86.2047 52.0933 88.8785 54 92 54V52ZM80.1268 50H85.9982V48H80.1268V50ZM74.125 54C77.2465 54 79.9203 52.0933 81.0498 49.3849L79.2039 48.6151C78.3742 50.6046 76.4114 52 74.125 52V54ZM67.2002 49.3849C68.3297 52.0933 71.0035 54 74.125 54V52C71.8386 52 69.8758 50.6046 69.0461 48.6151L67.2002 49.3849ZM41.1268 50H68.1232V48H41.1268V50ZM35.125 54C38.2465 54 40.9203 52.0933 42.0498 49.3849L40.2039 48.6151C39.3742 50.6046 37.4114 52 35.125 52V54ZM28.2002 49.3849C29.3297 52.0933 32.0035 54 35.125 54V52C32.8386 52 30.8758 50.6046 30.0461 48.6151L28.2002 49.3849ZM23.2518 50H29.1232V48H23.2518V50ZM17.25 54C20.3715 54 23.0453 52.0933 24.1748 49.3849L22.3289 48.6151C21.4992 50.6046 19.5364 52 17.25 52V54ZM10.3252 49.3849C11.4547 52.0933 14.1285 54 17.25 54V52C14.9636 52 13.0008 50.6046 12.1711 48.6151L10.3252 49.3849ZM5 50H11.2482V48H5V50ZM0 45C0 47.7614 2.23858 50 5 50V48C3.34315 48 2 46.6569 2 45H0ZM0 41V45H2V41H0Z"
    />
  </svg>
);

const IconCenterCarriage = ({ className }: IconProps) => (
  <svg
    width="106"
    height="54"
    viewBox="0 0 106 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      className="icFill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 5C1 2.79086 2.79086 1 5 1H101C103.209 1 105 2.79086 105 5V45C105 47.2091 103.209 49 101 49H98.0018C97.0223 51.349 94.7039 53 92 53C89.2961 53 86.9777 51.349 85.9982 49H80.1268C79.1473 51.349 76.8289 53 74.125 53C71.4211 53 69.1027 51.349 68.1232 49H41.1268C40.1473 51.349 37.8289 53 35.125 53C32.421 53 30.1027 51.349 29.1232 49H23.2518C22.2723 51.349 19.954 53 17.25 53C14.5461 53 12.2277 51.349 11.2482 49H5C2.79086 49 1 47.2091 1 45V5Z"
    />
    <path
      d="M98.0018 49V48H97.3354L97.0789 48.6151L98.0018 49ZM85.9982 49L86.9211 48.6151L86.6646 48H85.9982V49ZM80.1268 49V48H79.4604L79.2039 48.6151L80.1268 49ZM68.1232 49L69.0461 48.6151L68.7896 48H68.1232V49ZM41.1268 49V48H40.4604L40.2039 48.6151L41.1268 49ZM29.1232 49L30.0461 48.6151L29.7896 48H29.1232V49ZM23.2518 49V48H22.5854L22.3289 48.6151L23.2518 49ZM11.2482 49L12.1711 48.6151L11.9146 48H11.2482V49ZM5 0C2.23858 0 0 2.23857 0 5H2C2 3.34315 3.34315 2 5 2V0ZM101 0H5V2H101V0ZM106 5C106 2.23858 103.761 0 101 0V2C102.657 2 104 3.34315 104 5H106ZM106 45V5H104V45H106ZM101 50C103.761 50 106 47.7614 106 45H104C104 46.6569 102.657 48 101 48V50ZM98.0018 50H101V48H98.0018V50ZM97.0789 48.6151C96.2492 50.6046 94.2864 52 92 52V54C95.1215 54 97.7953 52.0933 98.9248 49.3849L97.0789 48.6151ZM92 52C89.7136 52 87.7508 50.6046 86.9211 48.6151L85.0752 49.3849C86.2047 52.0933 88.8785 54 92 54V52ZM80.1268 50H85.9982V48H80.1268V50ZM74.125 54C77.2465 54 79.9203 52.0933 81.0498 49.3849L79.2039 48.6151C78.3742 50.6046 76.4114 52 74.125 52V54ZM67.2002 49.3849C68.3297 52.0933 71.0035 54 74.125 54V52C71.8386 52 69.8758 50.6046 69.0461 48.6151L67.2002 49.3849ZM41.1268 50H68.1232V48H41.1268V50ZM35.125 54C38.2465 54 40.9203 52.0933 42.0498 49.3849L40.2039 48.6151C39.3742 50.6046 37.4114 52 35.125 52V54ZM28.2002 49.3849C29.3297 52.0933 32.0035 54 35.125 54V52C32.8386 52 30.8758 50.6046 30.0461 48.6151L28.2002 49.3849ZM23.2518 50H29.1232V48H23.2518V50ZM17.25 54C20.3715 54 23.0453 52.0933 24.1748 49.3849L22.3289 48.6151C21.4992 50.6046 19.5364 52 17.25 52V54ZM10.3252 49.3849C11.4547 52.0933 14.1285 54 17.25 54V52C14.9636 52 13.0008 50.6046 12.1711 48.6151L10.3252 49.3849ZM5 50H11.2482V48H5V50ZM0 45C0 47.7614 2.23858 50 5 50V48C3.34315 48 2 46.6569 2 45H0ZM0 5V45H2V5H0Z"
      className="icBorder"
    />
  </svg>
);

const IconEndCarriage = ({ className }: IconProps) => (
  <svg
    width="106"
    height="54"
    viewBox="0 0 106 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 5C1 2.79086 2.79086 1 5 1H65C87.0914 1 105 18.9086 105 41V45C105 47.2091 103.209 49 101 49H98.0018C97.0223 51.349 94.7039 53 92 53C89.2961 53 86.9777 51.349 85.9982 49H80.1268C79.1473 51.349 76.8289 53 74.125 53C71.4211 53 69.1027 51.349 68.1232 49H41.1268C40.1473 51.349 37.8289 53 35.125 53C32.421 53 30.1027 51.349 29.1232 49H23.2518C22.2723 51.349 19.954 53 17.25 53C14.5461 53 12.2277 51.349 11.2482 49H5C2.79086 49 1 47.2091 1 45V5Z"
      className="icFill"
    />
    <path
      d="M98.0018 49V48H97.3354L97.0789 48.6151L98.0018 49ZM85.9982 49L86.9211 48.6151L86.6646 48H85.9982V49ZM80.1268 49V48H79.4604L79.2039 48.6151L80.1268 49ZM68.1232 49L69.0461 48.6151L68.7896 48H68.1232V49ZM41.1268 49V48H40.4604L40.2039 48.6151L41.1268 49ZM29.1232 49L30.0461 48.6151L29.7896 48H29.1232V49ZM23.2518 49V48H22.5854L22.3289 48.6151L23.2518 49ZM11.2482 49L12.1711 48.6151L11.9146 48H11.2482V49ZM5 0C2.23858 0 0 2.23857 0 5H2C2 3.34315 3.34315 2 5 2V0ZM65 0H5V2H65V0ZM106 41C106 18.3563 87.6437 0 65 0V2C86.5391 2 104 19.4609 104 41H106ZM106 45V41H104V45H106ZM101 50C103.761 50 106 47.7614 106 45H104C104 46.6569 102.657 48 101 48V50ZM98.0018 50H101V48H98.0018V50ZM97.0789 48.6151C96.2492 50.6046 94.2864 52 92 52V54C95.1215 54 97.7953 52.0933 98.9248 49.3849L97.0789 48.6151ZM92 52C89.7136 52 87.7508 50.6046 86.9211 48.6151L85.0752 49.3849C86.2047 52.0933 88.8785 54 92 54V52ZM80.1268 50H85.9982V48H80.1268V50ZM74.125 54C77.2465 54 79.9203 52.0933 81.0498 49.3849L79.2039 48.6151C78.3742 50.6046 76.4114 52 74.125 52V54ZM67.2002 49.3849C68.3297 52.0933 71.0035 54 74.125 54V52C71.8386 52 69.8758 50.6046 69.0461 48.6151L67.2002 49.3849ZM41.1268 50H68.1232V48H41.1268V50ZM35.125 54C38.2465 54 40.9203 52.0933 42.0498 49.3849L40.2039 48.6151C39.3742 50.6046 37.4114 52 35.125 52V54ZM28.2002 49.3849C29.3297 52.0933 32.0035 54 35.125 54V52C32.8386 52 30.8758 50.6046 30.0461 48.6151L28.2002 49.3849ZM23.2518 50H29.1232V48H23.2518V50ZM17.25 54C20.3715 54 23.0453 52.0933 24.1748 49.3849L22.3289 48.6151C21.4992 50.6046 19.5364 52 17.25 52V54ZM10.3252 49.3849C11.4547 52.0933 14.1285 54 17.25 54V52C14.9636 52 13.0008 50.6046 12.1711 48.6151L10.3252 49.3849ZM5 50H11.2482V48H5V50ZM0 45C0 47.7614 2.23858 50 5 50V48C3.34315 48 2 46.6569 2 45H0ZM0 5V45H2V5H0Z"
      className="icBorder"
    />
  </svg>
);

const IconWifi = ({ className }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.7451 10.7507C18.3359 11.3415 18.8473 12.0581 19.237 12.82C19.4256 13.1888 19.2796 13.6406 18.9108 13.8292C18.5421 14.0179 18.0902 13.8718 17.9016 13.503C17.5812 12.8767 17.161 12.2879 16.6844 11.8113C14.1218 9.24869 9.96689 9.24869 7.40424 11.8113C6.90642 12.3092 6.50201 12.8689 6.19065 13.4845C6.00369 13.8541 5.5525 14.0022 5.18288 13.8152C4.81326 13.6283 4.66518 13.1771 4.85214 12.8074C5.23494 12.0506 5.73298 11.3613 6.34358 10.7507C9.49202 7.60225 14.5967 7.60225 17.7451 10.7507ZM15.6432 13.8195C16.0908 14.2671 16.4591 14.8168 16.7153 15.4017C16.8816 15.7811 16.7088 16.2234 16.3294 16.3896C15.95 16.5559 15.5077 16.3831 15.3415 16.0037C15.1584 15.5859 14.8948 15.1924 14.5826 14.8802C13.1798 13.4775 10.9056 13.4775 9.50281 14.8802C9.19229 15.1907 8.94019 15.5689 8.75561 15.9907C8.58957 16.3702 8.14734 16.5432 7.76786 16.3772C7.38838 16.2111 7.21536 15.7689 7.3814 15.3894C7.63885 14.8011 7.99524 14.2664 8.44215 13.8195C10.4307 11.831 13.6547 11.831 15.6432 13.8195ZM20.4482 8.2788C20.9573 8.78785 21.4379 9.36943 21.8564 9.97568C22.0916 10.3166 22.006 10.7837 21.6651 11.019C21.3242 11.2542 20.8571 11.1686 20.6218 10.8277C20.2538 10.2945 19.831 9.78285 19.3876 9.33946C15.3075 5.25941 8.69246 5.25941 4.61241 9.33946C4.19053 9.76134 3.77211 10.273 3.39038 10.8232C3.15427 11.1636 2.68698 11.2481 2.34664 11.012C2.00631 10.7758 1.92181 10.3086 2.15791 9.96822C2.58807 9.34815 3.06249 8.76806 3.55175 8.2788C8.21759 3.61296 15.7824 3.61296 20.4482 8.2788ZM13.0608 16.4392C13.6466 17.0251 13.6466 17.9749 13.0608 18.5608C12.4749 19.1466 11.5251 19.1466 10.9392 18.5608C10.3533 17.9749 10.3533 17.0251 10.9392 16.4392C11.5251 15.8533 12.4749 15.8533 13.0608 16.4392Z"
      fill="#28C281"
    />
  </svg>
);

const IconVideo = ({ className }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.75 4.5C15.5449 4.5 17 5.95507 17 7.75V7.923L20.8639 5.605C21.3638 5.30486 22 5.66493 22 6.248V17.75C22 18.333 21.364 18.6931 20.8641 18.3931L17 16.075V16.25C17 18.0449 15.5449 19.5 13.75 19.5H5.25C3.45507 19.5 2 18.0449 2 16.25V7.75C2 5.95507 3.45507 4.5 5.25 4.5H13.75ZM13.75 6H5.25C4.2835 6 3.5 6.7835 3.5 7.75V16.25C3.5 17.2165 4.2835 18 5.25 18H13.75C14.7165 18 15.5 17.2165 15.5 16.25V7.75C15.5 6.7835 14.7165 6 13.75 6ZM20.5 7.5731L17 9.6745V14.3254L20.5 16.4254V7.5731Z"
      fill="#28C281"
    />
  </svg>
);

const IconInfo = ({ className }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 1.99902C17.5237 1.99902 22.0015 6.47687 22.0015 12.0006C22.0015 17.5243 17.5237 22.0021 12 22.0021C6.47629 22.0021 1.99844 17.5243 1.99844 12.0006C1.99844 6.47687 6.47629 1.99902 12 1.99902ZM12 3.49902C7.30472 3.49902 3.49844 7.3053 3.49844 12.0006C3.49844 16.6959 7.30472 20.5021 12 20.5021C16.6953 20.5021 20.5015 16.6959 20.5015 12.0006C20.5015 7.3053 16.6953 3.49902 12 3.49902ZM11.9964 10.4996C12.376 10.4994 12.69 10.7813 12.7399 11.1473L12.7468 11.2491L12.7504 16.7507C12.7507 17.1649 12.4151 17.5009 12.0009 17.5012C11.6212 17.5014 11.3073 17.2195 11.2574 16.8535L11.2504 16.7517L11.2468 11.2501C11.2466 10.8359 11.5821 10.4999 11.9964 10.4996ZM12.0004 7.00184C12.552 7.00184 12.9991 7.44896 12.9991 8.0005C12.9991 8.55205 12.552 8.99917 12.0004 8.99917C11.4489 8.99917 11.0018 8.55205 11.0018 8.0005C11.0018 7.44896 11.4489 7.00184 12.0004 7.00184Z"
      fill="#28C281"
    />
  </svg>
);

const AllowedStatuses = Object.freeze({
  OK: "OK",
  ERROR: "ERROR",
  UNKNOWN: "UNKNOWN",
});

type AllowedStatuses = EnumLiteralsOf<typeof AllowedStatuses>;

type CarriageType = {
  title: string;
  status: AllowedStatuses;
  index: number;
  services: {
    wifi: AllowedStatuses;
    video: AllowedStatuses;
    info: AllowedStatuses;
  };
};

type CarriagesType = CarriageType[];

type TrainProps = {
  onCarClick?: (index: string) => void;
  cars?: {
    status: AllowedStatuses;
    index: number;
    number: string;
    signalSkdu: AllowedStatuses;
    signalSvnr: AllowedStatuses;
    signalWifi: AllowedStatuses;
  }[];
};
const CARRIAGE_MARGIN_RIGHT = 16;
const CARRIAGE_WIDTH = 104 + CARRIAGE_MARGIN_RIGHT;
const INITIAL_COUNT = 2;
const PADDING_RIGHT = 81;

export const Train = (props: TrainProps) => {
  const { id: carId } = useParams<{ id: string }>();
  const { cars, onCarClick } = props;
  const [maxCarriages, setMaxCarriages] = useState<number>(13);
  const refWrap = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperType>();
  const [progress, setProgress] = useState<{
    isBeginning: boolean;
    isEnd: boolean;
  }>({
    isBeginning: true,
    isEnd: false,
  });

  const carriages: CarriagesType =
    cars?.map((car) => ({
      index: car?.index,
      title: car?.number,
      status: car?.status,
      services: {
        wifi: car?.signalWifi,
        video: car?.signalSvnr,
        info: car?.signalSkdu,
      },
    })) || [];

  useEffect(() => {
    refWrap.current &&
      setMaxCarriages(
        (refWrap.current.offsetWidth - PADDING_RIGHT) / CARRIAGE_WIDTH
      );
    const onSetMaxCarriages = () => {
      refWrap.current &&
        setMaxCarriages(
          (refWrap.current.offsetWidth - PADDING_RIGHT) / CARRIAGE_WIDTH
        );
    };
    window.addEventListener("resize", onSetMaxCarriages);
    return () => window.removeEventListener("resize", onSetMaxCarriages);
  }, []);

  const carriagesWithError =
    carriages.length + INITIAL_COUNT > Math.round(maxCarriages)
      ? carriages
          .slice(
            Math.ceil(maxCarriages) - INITIAL_COUNT + 1,
            carriages.length + 1
          )
          .filter((el) => el.status === AllowedStatuses.ERROR)?.length
      : undefined;

  return (
    <div className={styles.trainSliderWrap} ref={refWrap}>
      <Swiper
        spaceBetween={CARRIAGE_MARGIN_RIGHT}
        slidesPerView="auto"
        modules={[FreeMode, Navigation]}
        onBeforeInit={(swiper: SwiperType) => {
          swiperRef.current = swiper;
        }}
        onReachBeginning={() => {
          setProgress((prev) => ({
            isBeginning: true,
            isEnd: prev?.isEnd,
          }));
        }}
        onReachEnd={() => {
          setProgress((prev) => ({
            isBeginning: prev?.isBeginning,
            isEnd: true,
          }));
        }}
        onSlideChange={(swiper: SwiperType) => {
          setProgress({
            isBeginning: swiper?.isBeginning,
            isEnd: swiper?.isEnd,
          });
        }}
      >
        <SwiperSlide>
          <div className={styles.trainCarriage}>
            <div className={styles.trainCarriagePosNum}></div>
            <div className={styles.trainCarriageIcon}>
              <div
                className={classNames(
                  styles.trainCarriageNum,
                  styles.trainCarriageTextDEFAULT
                )}
              ></div>
              <IconFirstCarriage className={styles.trainCarriageDEFAULT} />
            </div>
          </div>
        </SwiperSlide>
        {carriages.map((carriage, idx) => {
          return (
            <SwiperSlide key={idx}>
              <div
                className={classNames(
                  styles.trainCarriage,
                  onCarClick && styles.trainCarriageClick,
                  carId === carriage.title && styles.trainCarriageActive
                )}
                onClick={() => onCarClick && onCarClick(carriage.title)}
              >
                <div
                  className={classNames(
                    styles.trainCarriagePosNum,
                    "trainCarriagePosNumHover"
                  )}
                >
                  {carriage?.index}
                </div>
                <div className={styles.trainCarriageIcon}>
                  <div
                    className={classNames(
                      styles.trainCarriageNum,
                      styles.trainCarriageTextDEFAULT
                    )}
                  >
                    {carriage.title}
                  </div>
                  <IconCenterCarriage
                    className={classNames(
                      styles.trainCarriageDEFAULT,
                      carriage.status === AllowedStatuses.UNKNOWN &&
                        styles.trainCarriageDEFAULT,
                      carriage.status === AllowedStatuses.OK &&
                        styles.trainCarriageSUCCESS,
                      carriage.status === AllowedStatuses.ERROR &&
                        styles.trainCarriageERROR
                    )}
                  />
                </div>
                <div className={styles.trainCarriageServices}>
                  <div className={styles.trainCarriageServicesIcon}>
                    {carriage.services.wifi && (
                      <IconWifi
                        className={classNames(
                          carriage.services.wifi === AllowedStatuses.UNKNOWN &&
                            styles.trainCarriageServiceDEFAULT,
                          carriage.services.wifi === AllowedStatuses.OK &&
                            styles.trainCarriageServiceSUCCESS,
                          carriage.services.wifi === AllowedStatuses.ERROR &&
                            styles.trainCarriageServiceERROR
                        )}
                      />
                    )}
                  </div>
                  <div className={styles.trainCarriageServicesIcon}>
                    {carriage.services.video && (
                      <IconVideo
                        className={classNames(
                          carriage.services.video === AllowedStatuses.UNKNOWN &&
                            styles.trainCarriageServiceDEFAULT,
                          carriage.services.video === AllowedStatuses.OK &&
                            styles.trainCarriageServiceSUCCESS,
                          carriage.services.video === AllowedStatuses.ERROR &&
                            styles.trainCarriageServiceERROR
                        )}
                      />
                    )}
                  </div>
                  <div className={styles.trainCarriageServicesIcon}>
                    {carriage.services.info && (
                      <IconInfo
                        className={classNames(
                          carriage.services.info === AllowedStatuses.UNKNOWN &&
                            styles.trainCarriageServiceDEFAULT,
                          carriage.services.info === AllowedStatuses.OK &&
                            styles.trainCarriageServiceSUCCESS,
                          carriage.services.info === AllowedStatuses.ERROR &&
                            styles.trainCarriageServiceERROR
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <SwiperSlide>
          <div className={styles.trainCarriage}>
            <div className={styles.trainCarriagePosNum}></div>
            <div className={styles.trainCarriageIcon}>
              <div
                className={classNames(
                  styles.trainCarriageNum,
                  styles.trainCarriageTextDEFAULT
                )}
              ></div>
              <IconEndCarriage className={styles.trainCarriageDEFAULT} />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {!progress?.isBeginning && (
        <div
          className={styles.swiperPrev}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <IconLeft />
        </div>
      )}
      {!progress?.isEnd && carriages.length + INITIAL_COUNT > maxCarriages && (
        <div
          className={styles.swiperNext}
          onClick={() => swiperRef.current?.slideNext()}
        >
          {carriagesWithError ? (
            <div className={styles.swiperCount}>{carriagesWithError}</div>
          ) : undefined}
          <IconRight />
        </div>
      )}
    </div>
  );
};
