import moment from "moment";
import { DataSeries } from "./Chart";
import { EChartOption } from "echarts";

export const getLineOptions = (data: DataSeries[]) => {
  return {
    tooltip: {
      show: true,
      formatter: (params: EChartOption.Tooltip.Format) => {
        return `${params?.seriesName} <br /> ${moment(params?.data?.[0]).format(
          "DD.MM.YYYY HH:mm"
        )} <br /> ${params?.marker ?? ""} ${params?.data?.[1] ?? ""}`;
      },
    },
    grid: {
      show: true,
      zlevel: 2,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          show: false,
        },
        dataZoom: {
          show: false,
        },
        dataView: {
          show: false,
        },
        magicType: {
          show: false,
        },
        restore: { show: false },
      },
    },
    xAxis: {
      type: "time",
      axisLabel: {
        formatter: function (value: string) {
          return moment(value).format("DD.MM.YYYY HH:mm");
        },
        color: "rgba(46, 64, 87, 1)",
        fontWeight: "500",
        fontFamily: "Raleway",
        fontSize: "12px",
        rotate: 20,
      },
      splitLine: { show: true },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        color: "rgba(46, 64, 87, 1)",
        fontWeight: "500",
        fontFamily: "Raleway",
        fontSize: "12px",
      },
    },
    series: data,
    legend: {
      type: "scroll",
      y: "bottom",
      show: true,
      icon: "pin",
      padding: [0, 60],
      textStyle: {
        color: "rgba(46, 64, 87, 1)",
        fontWeight: "500",
        fontFamily: "Raleway",
        fontSize: "12px",
      },
      selected: {} as { [key: string]: boolean },
    },
  };
};
