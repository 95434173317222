import moment from "moment";
import { FiltersFromUrlQueryParams } from "../../hooks/useFiltersFromUrlQueryParams";
import { useQuery } from "@tanstack/react-query";
import { getFilters } from "./api/getFilters";
import { routesVariables } from "../../constants";
import { DatePicker } from "../DatePicker";
import { Checkbox } from "../Checkbox";
import { CustomSelect } from "../Select";
import { Tooltip } from "../Tooltip";
import { Button } from "../Button";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import styles from "./filters.module.sass";

type FiltersProps = {
  disabledDate?: boolean;
  disabledBranches?: boolean;
  disabledRoutes?: boolean;
  disabledCars?: boolean;
  disabledOnWay?: boolean;
  disabledSubmit?: boolean;
  disabledDepots?: boolean;
  hideDepots?: boolean;
  onSubmit?: () => void;
  filters: Record<string, string>;
  updateFilters: (...values: any[]) => void;
  filtersIsInitialized: boolean;
};

export const Filters = ({
  disabledDate,
  disabledBranches,
  disabledRoutes,
  disabledCars,
  disabledOnWay,
  disabledSubmit,
  disabledDepots,
  hideDepots = false,
  onSubmit,
  filters,
  updateFilters,
  filtersIsInitialized,
}: FiltersProps) => {
  const location = useLocation();
  const [tmpFilters, setTmpFilters] = useState<FiltersFromUrlQueryParams>();

  const handleChangeDatePicker = (dates: { from: Date; to: Date }) => {
    setTmpFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        ...{
          beginDate: moment(dates.from).format("YYYY-MM-DD"),
          endDate: moment(dates.to).format("YYYY-MM-DD"),
        },
      };
      return newFilters;
    });
  };

  const handleChangeFilter = (key: string, value?: string[] | string) => {
    setTmpFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        ...{
          [key]: value,
        },
      };
      return newFilters;
    });
  };

  const handleSubmit = () => {
    updateFilters(tmpFilters);
  };

  //Вызвать onSubmit(перезапросить данные) после того как изменился filters
  useEffect(() => {
    setTimeout(() => {
      onSubmit && onSubmit();
    }, 0);
  }, [JSON.stringify(filters)]);

  const { data } = useQuery({
    queryKey: [
      "filters",
      tmpFilters?.beginDate,
      tmpFilters?.endDate,
      tmpFilters?.branches,
    ],
    queryFn: () =>
      getFilters({
        beginDate: moment(tmpFilters?.beginDate as Date).format("YYYY-MM-DD"),
        endDate: moment(tmpFilters?.endDate as Date).format("YYYY-MM-DD"),
        branchId: !matchPath(routesVariables.filials, location.pathname)
          ? (tmpFilters?.branches as string)
          : undefined,
      }),
    refetchOnWindowFocus: false,
    enabled: tmpFilters?.beginDate && tmpFilters?.endDate ? true : false,
  });

  const branchesOptions =
    data?.filterBranches.map((el) => ({
      label: el?.name,
      value: `${el?.id}`,
    })) || [];

  const carsOptions =
    data?.filterCars.map((el) => ({ label: el?.number, value: el?.number })) ||
    [];

  const routesOptions =
    data?.filterRoutes.map((el) => ({
      label: el?.number,
      value: el?.number,
    })) || [];

  const depotsOptions =
    data?.filterDepots?.map((el) => ({
      label: el?.name,
      value: `${el?.id}`,
    })) || [];

  useEffect(() => {
    setTmpFilters(filters);

    if (!filters?.beginDate && !filters?.endDate && filtersIsInitialized) {
      updateFilters({
        beginDate: moment().format("YYYY-MM-DD"),
        endDate: moment(new Date(moment().add(1, "day").toDate())).format(
          "YYYY-MM-DD"
        ),
      });
    }
  }, [filters]);

  return (
    <div className={styles.filters}>
      <div className={styles.filterItemDate}>
        <DatePicker
          disabledDays={{
            before: new Date(
              moment().subtract(12, "month").format("YYYY-MM-DD")
            ),
            after: new Date(moment().add(12, "month").format("YYYY-MM-DD")),
          }}
          disabled={disabledDate}
          value={{
            from: new Date(
              moment(tmpFilters?.beginDate as unknown as string).toDate()
            ),
            to: new Date(
              moment(tmpFilters?.endDate as unknown as string).toDate()
            ),
          }}
          placeHolder="Даты"
          onChange={handleChangeDatePicker}
        />
      </div>
      <div className={styles.filterItemSelect}>
        <CustomSelect
          disabled={disabledBranches}
          label="Филиал"
          labelValue="Филиалы"
          options={branchesOptions}
          onSubmit={(data) => handleChangeFilter("branches", data)}
          values={tmpFilters?.branches as unknown as string[]}
          type="radio"
        />
      </div>
      {!hideDepots && (
        <div className={styles.filterItemSelect}>
          <CustomSelect
            disabled={disabledDepots}
            label="Депо"
            labelValue="Депо"
            options={depotsOptions}
            onSubmit={(data) => handleChangeFilter("depots", data)}
            values={tmpFilters?.depots as unknown as string[]}
          />
        </div>
      )}
      <div className={styles.filterItemSelect}>
        <CustomSelect
          disabled={disabledRoutes}
          label="Рейс"
          labelValue="Рейсы"
          options={routesOptions}
          onSubmit={(data) => handleChangeFilter("routes", data)}
          values={tmpFilters?.routes as unknown as string[]}
        />
      </div>
      <div className={styles.filterItemSelect}>
        <CustomSelect
          disabled={disabledCars}
          label="Вагон"
          labelValue="Вагоны"
          options={carsOptions}
          onSubmit={(data) => handleChangeFilter("cars", data)}
          values={tmpFilters?.cars as unknown as string[]}
        />
      </div>
      <Tooltip
        title={
          <div className={styles.checkboxTooltip}>
            Показать только те поезда, которые на данный момент времени
            находятся в пути
          </div>
        }
        placement="top"
        slotProps={{
          tooltip: {
            sx: {
              backgroundColor: "rgba(255, 255, 255, 1)",
              maxWidth: "250px",
              boxShadow: "0px 0px 16px 0px rgba(46, 64, 87, 0.5)",
              padding: 0,
            },
          },
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -6],
                },
              },
            ],
          },
        }}
      >
        <div className="">
          <Checkbox
            label="В пути"
            onChange={(_, val) =>
              handleChangeFilter(
                "onWay",
                val === true ? String(val) : undefined
              )
            }
            checked={tmpFilters?.onWay === "true" ? true : false}
            disabled={disabledOnWay}
          />
        </div>
      </Tooltip>
      <Button
        disabled={disabledSubmit}
        variant="contained"
        sx={{
          background: "rgba(61, 92, 221, 1)",
          height: "40px",
          textTransform: "capitalize",
          fontFamily: "Raleway",
          fontSize: "14px",
          color: "#fff",
          boxShadow: "0px 0px 8px 0px rgba(61, 92, 221, 0.08)",
          borderRadius: "4px",
        }}
        onClick={handleSubmit}
      >
        Применить
      </Button>
    </div>
  );
};
