import { TableFilials as Table } from "./components/Table";
import { Filters } from "../../components/Filters";
import { Layout } from "../../layouts/Layout";
import { BreadcrumbItem, Breadcrumbs } from "../../components/Breadcrumbs";
import {
  ALLOWED_FILTERS,
  COOKIE_AUTH_TOKEN,
  routesVariables,
} from "../../constants";
import useFiltersFromUrlQueryParams from "hooks/useFiltersFromUrlQueryParams";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { getFilials } from "./api/getFilials";
import { Button } from "../../components/Button";
import { useMemo } from "react";
import { FilialsResponse, TableFilialsRow } from "./@types";
import Cookies from "js-cookie";
import { CircularProgress } from "../../components/CircularProgress";

import styles from "./page.module.sass";

const ALLOWED_FILTERS_FILIALS = ALLOWED_FILTERS.filter((el) => el !== "depots");

export default function Filials() {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  const { filters, updateFilters, filtersIsInitialized } =
    useFiltersFromUrlQueryParams(
      ALLOWED_FILTERS_FILIALS as unknown as string[]
    ) as unknown as {
      filters: Record<(typeof ALLOWED_FILTERS_FILIALS)[number], string>;
      updateFilters: (...values: any[]) => void;
      filtersIsInitialized: boolean;
      setFiltersIsInitialized: (flag: boolean) => void;
    };

  const { data, refetch, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<FilialsResponse | undefined>({
      queryKey: ["filials", token],
      queryFn: ({ pageParam }) =>
        getFilials({ ...filters, page: pageParam as number }),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage) return;
        return lastPage?.total !==
          allPages?.reduce((acc, el) => {
            if (!el?.data) return acc;
            return acc + el?.data?.length;
          }, 0)
          ? lastPage?.page + 1
          : undefined;
      },
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      enabled: false,
    });

  const filials = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return [...acc, ...(page?.data ?? [])];
    }, [] as TableFilialsRow[]);
  }, [data]);

  const breadcrumbsItems = [
    {
      path: routesVariables.filials,
      title: "Филиалы",
      current: true,
    },
  ];

  return (
    <Layout>
      <div className={styles.pageWrapper}>
        <Breadcrumbs>
          {breadcrumbsItems?.map((el, key) => (
            <BreadcrumbItem
              current={el.current}
              key={key}
              breadCrumbPathName={el.path}
            >
              {el.title}
            </BreadcrumbItem>
          ))}
        </Breadcrumbs>
        <div className={styles.title}>Филиалы</div>
        <div className={styles.filtersWrap}>
          <Filters
            filters={filters}
            updateFilters={updateFilters}
            onSubmit={() => refetch()}
            filtersIsInitialized={filtersIsInitialized}
            hideDepots
          />
        </div>
        <div className={styles.tableWrap}>
          {isFetching && (
            <div className={styles.loaderWrap}>
              <CircularProgress />
            </div>
          )}

          {filials && !isFetching && <Table data={filials} />}
        </div>
        {hasNextPage && (
          <div className={styles.showMore}>
            <Button
              disabled={isFetching}
              variant="contained"
              sx={{
                background: "rgba(61, 92, 221, 1)",
                height: "40px",
                textTransform: "capitalize",
                fontFamily: "Raleway",
                fontSize: "14px",
                color: "#fff",
                boxShadow: "0px 0px 8px 0px rgba(61, 92, 221, 0.08)",
                borderRadius: "4px",
              }}
              onClick={() => fetchNextPage()}
            >
              Показать еще
            </Button>
          </div>
        )}
      </div>
    </Layout>
  );
}
