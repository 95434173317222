import { api } from "../../../api";
import { GetReportRequest } from "../@types";
import queryString from "query-string";

export const getReport = async (
  params: GetReportRequest
): Promise<any | undefined> => {
  if (
    !params.graphId ||
    !params.graphItemIds ||
    !params.hostId ||
    !params.carriageNumber
  )
    return;

  await api
    .get(`/fpk/report`, {
      params: {
        ...params,
      },
      responseType: "blob",
      headers: {
        "content-type": "application/msexcel;charset=UTF-8",
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      paramsSerializer: (params) => {
        return queryString.stringify(params);
      },
    })
    .then((response) => {
      const disposition = response?.headers?.["content-disposition"];
      let filename = "";
      if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      const href = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = href;
      link.style.display = "none";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};
