import { MonitorDataItem, MonitorDataTypes } from "../@types";

export const getSubTabs = (
  itemType: MonitorDataTypes,
  monitorDataItems?: MonitorDataItem[]
) => {
  if (!monitorDataItems) return [];

  const filteMonitorDataItem = monitorDataItems.find(
    (el) => el.type === itemType
  );

  return filteMonitorDataItem
    ? filteMonitorDataItem.monitoringData.map((el) => ({
        hostId: el.hostId,
        name: el.name || el.hostName,
      }))
    : [];
};
