export const ALLOWED_FILTERS = [
  "beginDate",
  "endDate",
  "branches",
  "routes",
  "cars",
  "depots",
  "onWay",
] as const;

export const routesVariables = {
  login: "/login",
  filials: "/",
  filialDetail: "/filial/:id",
  carriageDetail: "/carriage/:id",
};

export const COOKIE_AUTH_TOKEN = "auth-token";
export const COOKIE_REFRESH_AUTH_TOKEN = "refresh-token";
