import classNames from "classnames";
import React from "react";
import styles from "./tab.module.sass";

export type TabProps = {
  tabIndex: number;
  onClick?: (newValue: number) => void;
  isActive?: boolean;
  label: string;
  tabClassname?: string;
  activeClassname?: string;
  externalLabelClassname?: string;
  icon?: React.ReactElement;
  orderIcon?: "left" | "right" | "center";
  positionLabel?: "inButton" | "bottom" | "top";
  isUnderline?: boolean;
  isSmall?: boolean;
};

export const Tab: React.FC<TabProps> = ({
  onClick,
  tabIndex,
  isActive,
  label,
  tabClassname,
  activeClassname,
  externalLabelClassname,
  icon,
  orderIcon = "left",
  positionLabel = "inButton",
  isUnderline = true,
  isSmall = false,
}) => {
  const handleClick = () => {
    onClick?.(tabIndex);
  };
  const buttonClasses =
    tabClassname || classNames(styles.button, isSmall && styles.small);
  const activeTabClass = activeClassname || styles.buttonActive;

  return (
    <div className={styles.wrap}>
      <div
        className={classNames(
          buttonClasses,
          icon && styles.withIcon,
          isActive && activeTabClass,
          isUnderline && styles.underline
        )}
        tabIndex={tabIndex}
        onClick={handleClick}
      >
        {icon && (
          <div
            className={classNames(
              styles.icon,
              orderIcon === "right" && styles.iconRight,
              orderIcon === "left" && styles.iconLeft
            )}
          >
            {icon}
          </div>
        )}
        <div>{positionLabel === "inButton" && label}</div>
      </div>
      {positionLabel !== "inButton" && (
        <div
          className={classNames(
            positionLabel === "bottom" && styles.externalLabelBottom,
            positionLabel === "top" && styles.externalLabelTop,
            externalLabelClassname
          )}
        >
          {label}
        </div>
      )}
    </div>
  );
};
