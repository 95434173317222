import { Input } from "../../../../components/Input";
// import { Checkbox } from "../../../../components/Checkbox";
import { Button } from "../../../../components/Button";
import { Alert } from "../../../../components/Alert";
import { ChangeEvent, FormEvent, useState } from "react";
import Cookies from "js-cookie";
import { login } from "../../api/login";
import {
  COOKIE_AUTH_TOKEN,
  COOKIE_REFRESH_AUTH_TOKEN,
  routesVariables,
} from "../../../../constants";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconAlert } from "./assets/ic_alert.svg";

import styles from "./authForm.module.sass";

export const AuthForm = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [input, setInput] = useState({
    login: "",
    password: "",
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitEvent = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    if (input.login !== "" && input.password !== "") {
      try {
        const body = {
          login: input.login,
          password: input.password,
        };

        const auth = await login(body);

        Cookies.set(COOKIE_AUTH_TOKEN, auth?.accessToken ?? "");
        Cookies.set(COOKIE_REFRESH_AUTH_TOKEN, auth?.refreshToken ?? "");

        navigate(routesVariables.filials);
      } catch (error) {
        setError(true);
        console.error(error);
      }
    }
  };
  return (
    <div className={styles.authForm}>
      <div className={styles.title}>Вход</div>
      {error && (
        <div className={styles.alert}>
          <Alert
            icon={<IconAlert />}
            variant="outlined"
            severity="error"
            sx={{
              color: "rgba(46, 64, 87, 1)",
              fontWeight: 400,
              fontFamily: "Raleway",
              fontSize: "14px",
              padding: "8px 16px",
            }}
          >
            Ошибка авторизации
          </Alert>
        </div>
      )}
      <form onSubmit={handleSubmitEvent}>
        <div className={styles.form}>
          <div className={styles.formItem}>
            <Input
              label="E-mail"
              type="text"
              placeholder="example@example.ru"
              name="login"
              onChange={handleInput}
              required
            />
          </div>
          <div className={styles.formItem}>
            <Input
              label="Пароль"
              type="password"
              placeholder="Введите свой пароль"
              name="password"
              onChange={handleInput}
              required
            />
          </div>
          {/* <div className={styles.formHelpers}>
            <div className={styles.formForgotPass}>
              <a href="">Забыли пароль?</a>
            </div>
            <div className={styles.formRememberPass}>
              <Checkbox label="Запомнить меня" />
            </div>
          </div> */}
          <div className={styles.formButtonWrap}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                background: "rgba(61, 92, 221, 1)",
                height: "48px",
                textTransform: "capitalize",
                fontFamily: "Raleway",
                fontSize: "14px",
                color: "#fff",
                boxShadow: "0px 0px 8px 0px rgba(61, 92, 221, 0.08)",
                borderRadius: "4px",
              }}
            >
              Войти
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
