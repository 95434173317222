import { api } from "../../../api";
import { CarsByTripRequest, CarsByTripResponse } from "../@types";
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const getCarsByTrip = async (
  params: CarsByTripRequest
): Promise<CarsByTripResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  if (!params?.tripId) return;

  const resp = await api.get(`/fpk/trips/${params?.tripId}/cars`, {
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
};
