import queryString from "query-string";
import { api } from "../../../api";
import { FilialDetailRequest, FilialDetailResponse } from "../@types";
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const getFilialDetail = async (
  params: FilialDetailRequest
): Promise<FilialDetailResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  if (!params?.beginDate || !params.endDate || !params.branch) return;
  if (params?.branches) {
    delete params?.branches;
  }

  const branch = params.branch;

  params.branch = undefined;

  const resp = await api.get(`/fpk/${branch}/trips`, {
    params: {
      ...params,
      pageSize: 5,
    },
    headers: {
      Authorization: token,
    },
    paramsSerializer: (params) => {
      return queryString.stringify(params);
    },
  });

  return resp.data;
};
