import { useEffect, useRef, useState } from "react";

export const useDetectSticky = (
  ref?: React.MutableRefObject<HTMLDivElement | null>,
  observerSettings = { threshold: [1] }
) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef<HTMLDivElement>(null);
  ref ||= newRef;

  useEffect(() => {
    const cachedRef = ref?.current,
      observer = new IntersectionObserver(([e]) => {
        setIsSticky(e.boundingClientRect.y < 0);
      }, observerSettings);

    cachedRef && observer.observe(cachedRef);

    return () => {
      cachedRef && observer.unobserve(cachedRef);
    };
  }, []);

  return { isSticky, ref, setIsSticky };
};
