import Cookies from "js-cookie";
import { api } from "../../../api";
import { UserResponse } from "../@types";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const user = async (): Promise<UserResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);
  const resp = await api.get(`/fpk/auth/user`, {
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
};
