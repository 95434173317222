import Cookies from "js-cookie";
import { api } from "../../../api";
import { FiltersRequest, FiltersResponse } from "../@types";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const getFilters = async (
  params: FiltersRequest
): Promise<FiltersResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  if (!params?.beginDate && !params.endDate) return;
  const resp = await api.get(`/fpk/filters`, {
    params: {
      ...params,
    },
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
};
