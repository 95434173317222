import { Drawer } from "../../../../components/Drawer";
import classnames from "classnames";
import { ReactComponent as IconClose } from "./assets/ic_close.svg";
import { RouteByTripResponse } from "../../@types";
import moment from "moment";
import "moment/locale/ru";
// import { ReactComponent as IconStep } from "./assets/ic_step.svg";

import styles from "./drawerTrack.module.sass";

type DrawerTrackProps = {
  data?: RouteByTripResponse;
  isOpen: boolean;
  toggleDrawer: (newOpen: boolean) => () => void;
};

export const DrawerTrack = ({
  isOpen,
  toggleDrawer,
  data,
}: DrawerTrackProps) => {
  const serviceLevel = 0;
  return (
    <Drawer
      PaperProps={{
        sx: {
          background: "rgba(225, 231, 243, 1)",
          boxShadow: "0px 0px 8px 0px rgba(61, 92, 221, 0.08)",
        },
      }}
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer(false)}
    >
      <div className={styles.drawerContent}>
        <div className={styles.drawerHeader}>
          <div className={styles.drawerHeaderTitle}>
            <div
              className={styles.drawerHeaderClose}
              onClick={toggleDrawer(false)}
            >
              <IconClose />
            </div>
            <div className={styles.drawerHeaderNum}>{data?.route}</div>
            <div
              className={classnames(
                styles.drawerHeaderSteps,
                serviceLevel < 2 && styles.drawerHeaderStepsError,
                serviceLevel > 2 &&
                  serviceLevel <= 4 &&
                  styles.drawerHeaderStepsDefault,
                serviceLevel >= 5 && styles.drawerHeaderStepsSuccess
              )}
            >
              {/* <IconStep /> {serviceLevel} */}
            </div>
          </div>
          <div className={styles.drawerHeaderCaption}>{data?.name}</div>
        </div>
        <div className={styles.drawerInfo}>
          <div className={styles.drawerInfoItem}>
            <div className={styles.drawerInfoTimeWrap}>
              <div
                className={classnames(styles.drawerInfoTitle, "drawerTitle")}
              >
                {data?.departureStation?.departureTime}
              </div>
              <div
                className={classnames(
                  styles.drawerInfoCaption,
                  "drawerCaption"
                )}
              >
                {moment(data?.departureStation?.departureDate)
                  .locale("ru")
                  .format("DD MMM, dd")}
              </div>
            </div>
            <div
              className={classnames(
                styles.drawerInfoTextWrap,
                "drawerInfoText"
              )}
            >
              <div
                className={classnames(styles.drawerInfoTitle, "drawerTitle")}
              >
                {data?.departureStation?.stationName}
              </div>
              <div
                className={classnames(
                  styles.drawerInfoCaption,
                  "drawerCaption"
                )}
              ></div>
            </div>
          </div>
          {data?.intermediateStations?.map((station, idx) => (
            <div className={styles.drawerInfoItem} key={idx}>
              <div className={styles.drawerInfoTimeWrap}>
                <div
                  className={classnames(styles.drawerInfoTitle, "drawerTitle")}
                >
                  {station?.arrivalTime} - {station?.departureTime}
                </div>
                <div
                  className={classnames(
                    styles.drawerInfoCaption,
                    "drawerCaption"
                  )}
                >
                  {station?.stopDuration} стоянка
                </div>
              </div>
              <div
                className={classnames(
                  styles.drawerInfoTextWrap,
                  "drawerInfoText"
                )}
              >
                <div
                  className={classnames(styles.drawerInfoTitle, "drawerTitle")}
                >
                  {station?.stationName}
                </div>
              </div>
            </div>
          ))}
          <div className={styles.drawerInfoItem}>
            <div className={styles.drawerInfoTimeWrap}>
              <div
                className={classnames(styles.drawerInfoTitle, "drawerTitle")}
              >
                {data?.arrivalStation?.departureTime}
              </div>
              <div
                className={classnames(
                  styles.drawerInfoCaption,
                  "drawerCaption"
                )}
              >
                {moment(data?.arrivalStation?.departureDate)
                  .locale("ru")
                  .format("DD MMM, dd")}
              </div>
            </div>
            <div
              className={classnames(
                styles.drawerInfoTextWrap,
                "drawerInfoText"
              )}
            >
              <div
                className={classnames(styles.drawerInfoTitle, "drawerTitle")}
              >
                {data?.arrivalStation?.stationName}
              </div>
              <div
                className={classnames(
                  styles.drawerInfoCaption,
                  "drawerCaption"
                )}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
