import Cookies from "js-cookie";
import { api } from "../../../api";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const logout = async (): Promise<undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  const resp = await api.post(
    `/fpk/auth/logout`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return resp.data;
};
