import { RefObject, useEffect } from "react";

export const useOnClickOutside = (
  refs: RefObject<HTMLElement>[],
  handler: (event: MouseEvent | TouchEvent) => void,
  disabled?: boolean
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (disabled) {
        return;
      }

      const isClickOutside = refs.every(
        (ref) => !ref.current?.contains(event.target as Node)
      );

      if (isClickOutside) {
        handler(event);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [refs, handler]);
};
