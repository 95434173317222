import axios from "axios";
import {
  COOKIE_AUTH_TOKEN,
  COOKIE_REFRESH_AUTH_TOKEN,
  routesVariables,
} from "../constants";
import Cookies from "js-cookie";

export const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
});

type RefreshTokens = {
  accessToken: string;
  refreshToken: string;
};

const refresh = async (): Promise<RefreshTokens | undefined> => {
  const refreshToken = Cookies.get(COOKIE_REFRESH_AUTH_TOKEN);

  const resp = await api.post(`/fpk/auth/refresh`, {
    refreshToken,
  });

  return resp.data;
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 403) {
      if (Cookies.get(COOKIE_REFRESH_AUTH_TOKEN)) {
        try {
          const newTokens = await refresh();

          Cookies.remove(COOKIE_AUTH_TOKEN);
          Cookies.remove(COOKIE_REFRESH_AUTH_TOKEN);

          Cookies.set(COOKIE_AUTH_TOKEN, newTokens?.accessToken ?? "");
          Cookies.set(COOKIE_REFRESH_AUTH_TOKEN, newTokens?.refreshToken ?? "");
        } catch (error) {
          console.error(error);
          Cookies.remove(COOKIE_AUTH_TOKEN);
          Cookies.remove(COOKIE_REFRESH_AUTH_TOKEN);
          window.location.href = routesVariables.login;
        }
      } else {
        window.location.href = routesVariables.login;
      }
    } else {
      return Promise.reject(error);
    }
  }
);
