import { TrainCard } from "./components/TrainCard";
import { useState } from "react";
import { Layout } from "../../layouts/Layout";
import { BreadcrumbItem, Breadcrumbs } from "../../components/Breadcrumbs";
import { Filters } from "../../components/Filters";
import { SideBarSlider } from "./components/SideBarSlider";
import { Tab, TabPanel, Tabs } from "../../components/Tabs";
import { DrawerTrack } from "./components/DrawerTrack/DrawerTrack";
import {
  ALLOWED_FILTERS,
  COOKIE_AUTH_TOKEN,
  routesVariables,
} from "../../constants";
import { generateLinkMask } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCarByTrip } from "./api/getCarByTrip";
import { getCarsByTrip } from "./api/getCarsByTrip";
import { getRouteByTrip } from "./api/getRouteByTrip";
import { CarriageItem } from "./components/CarriageItem";
import { Table, CellAligns } from "../../components/Table";
import { EnumTabs, MonitorDataTypes, MonitorDataTypesArray } from "./@types";
import moment from "moment";
import { getSubTabs } from "./utils/getSubTabs";
import { TabPanelItem } from "./components/TabPanelItem";
import Cookies from "js-cookie";
import useFiltersFromUrlQueryParams from "../../hooks/useFiltersFromUrlQueryParams";

import styles from "./page.module.sass";
import { getTimeline } from "./api/getTimeline";

const tabs = [
  {
    value: EnumTabs.information,
    label: "Информация",
    type: MonitorDataTypes.INFORMATION,
  },
  {
    value: EnumTabs.wifi,
    label: "WI-FI",
    type: MonitorDataTypes.WI_FI,
  },
  {
    value: EnumTabs.im,
    label: "ИМ",
    type: MonitorDataTypes.KIM,
  },
  {
    value: EnumTabs.skdy,
    label: "СКДУ",
    type: MonitorDataTypes.SKDU,
  },
  {
    value: EnumTabs.svnr,
    label: "СВНР",
    type: MonitorDataTypes.SVNR,
  },
];

export default function CarriageDetail() {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [filialName, setFilialName] = useState<string>();
  const [selectedIntreval, setSelectedIntreval] = useState<{
    startDate: string;
    endDate: string;
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const { id: carId } = useParams<{ id: string }>();
  const tripId = searchParams.get("tripId") || undefined;
  const hostId = searchParams.get("hostId") || undefined;
  const branches = searchParams.get("branches") || undefined;
  const [currentTab, setCurrentTab] = useState<number>(EnumTabs.information);
  const { filters, updateFilters, filtersIsInitialized } =
    useFiltersFromUrlQueryParams(
      ALLOWED_FILTERS as unknown as string[]
    ) as unknown as {
      filters: Record<(typeof ALLOWED_FILTERS)[number], string>;
      updateFilters: (...values: any[]) => void;
      filtersIsInitialized: boolean;
    };

  const onHostIdClick = (hostId: number, type?: MonitorDataTypes) => {
    const search = new URLSearchParams(location.search);
    search.set("hostId", `${hostId}`);
    if (type) {
      switch (type) {
        case MonitorDataTypes.WI_FI:
          setCurrentTab(EnumTabs.wifi);
          break;
        case MonitorDataTypes.KIM:
          setCurrentTab(EnumTabs.im);
          break;
        case MonitorDataTypes.SKDU:
          setCurrentTab(EnumTabs.skdy);
          break;
        case MonitorDataTypes.SVNR:
          setCurrentTab(EnumTabs.svnr);
          break;
        default:
          setCurrentTab(EnumTabs.information);

          break;
      }
    }
    navigate(`${location.pathname}?${search.toString()}`);
  };

  const { data: carByTrip } = useQuery({
    queryKey: ["carByTrip", tripId, carId, token],
    queryFn: () => getCarByTrip({ tripId, carId }),
    refetchOnWindowFocus: false,
  });

  const { data: carsByTrip } = useQuery({
    queryKey: ["carsByTrip", tripId, token],
    queryFn: () => getCarsByTrip({ tripId }),
    refetchOnWindowFocus: false,
  });

  const carsIds = carsByTrip?.cars.map((el) => el.number);

  const { data: routeByTrip } = useQuery({
    queryKey: ["routeByTrip", tripId, token],
    queryFn: () => getRouteByTrip({ tripId }),
    refetchOnWindowFocus: false,
  });

  const { data: timeline } = useQuery({
    queryKey: ["timelineByTrip", tripId, token],
    queryFn: () => getTimeline({ tripId }),
    refetchOnWindowFocus: false,
  });

  const toggleDrawer = (newOpen: boolean) => () => {
    setIsOpenDrawer(newOpen);
  };

  const filterTabs = tabs.filter(
    (el) =>
      el.type === MonitorDataTypes.INFORMATION ||
      carByTrip?.monitoringDataTypes?.find(
        (dataMonitoring) => dataMonitoring?.type === el.type
      )
  );

  const handleChangeTab = (newValue: number) => {
    const search = new URLSearchParams(location.search);
    search.delete("hostId");
    navigate(`${location.pathname}?${search.toString()}`);
    setCurrentTab(newValue);

    const tabs = getSubTabs(
      MonitorDataTypesArray[newValue] as MonitorDataTypes,
      carByTrip?.monitoringDataTypes
    );

    onHostIdClick(tabs?.[0]?.hostId);
  };

  const breadcrumbsItems = [
    {
      path: `${routesVariables.filials}${location.search}`,
      title: "Филиалы",
    },
    {
      path: `${generateLinkMask(routesVariables.filialDetail, {
        id: branches ?? "",
      })}${location.search}`,
      title: filialName,
    },
    {
      path: `${location.pathname}${location.search}`,
      title: "Состав поезда",
      current: true,
    },
  ];

  const tableInfoHead = [
    {
      title: "Информация по вагону",
      align: "left" as CellAligns,
    },
    {
      title: "",
      align: "left" as CellAligns,
    },
  ];

  const tableInfoRow = carByTrip
    ? [
        {
          id: "null",
          data: ["ID", carId ?? ""],
        },
        {
          id: "null",
          data: [
            "Отправление",
            carByTrip?.info?.departureDateTime
              ? moment(carByTrip?.info?.departureDateTime).format(
                  "DD.MM.YYYY HH:mm"
                )
              : "",
          ],
        },
        {
          id: "null",
          data: [
            "Дата проведения",
            carByTrip?.info?.maintenanceDateTime
              ? moment(carByTrip?.info?.maintenanceDateTime).format(
                  "DD.MM.YYYY HH:mm"
                )
              : "",
          ],
        },
        {
          id: "null",
          data: ["Место проведения", carByTrip?.info?.maintenancePlace],
        },
        {
          id: "null",
          data: ["Инженер", carByTrip?.info?.engineer],
        },
        {
          id: "null",
          data: ["Телефон", carByTrip?.info?.phone],
        },
      ]
    : [];

  return (
    <Layout>
      <div className={styles.pageWrapper}>
        <Breadcrumbs>
          {breadcrumbsItems?.map((el, key) => (
            <BreadcrumbItem
              current={el.current}
              key={key}
              breadCrumbPathName={el.path}
            >
              {el.title}
            </BreadcrumbItem>
          ))}
        </Breadcrumbs>
        <div className={styles.title}>Состав поезда</div>
        <div className={styles.filtersWrap}>
          <Filters
            disabledDate
            disabledBranches
            disabledRoutes
            disabledCars
            disabledSubmit
            disabledOnWay
            disabledDepots
            filters={filters}
            updateFilters={updateFilters}
            filtersIsInitialized={filtersIsInitialized}
          />
        </div>
        <div className={styles.carriageWrap}>
          <div className={styles.carriageSideBar}>
            <SideBarSlider setFilialName={setFilialName} />
          </div>
          <div className={styles.carriageDetailWrap}>
            <div className={styles.carriageDetail}>
              {carsByTrip && (
                <TrainCard
                  onHeaderClick={toggleDrawer(true)}
                  route={carsByTrip?.route}
                  name={carsByTrip?.name}
                  cars={carsByTrip.cars}
                />
              )}
              <CarriageItem
                carId={carId}
                carsIds={carsIds}
                monitorDataItem={carByTrip?.monitoringDataTypes}
                onServiceClick={onHostIdClick}
              />
              <div className={styles.tabs}>
                <Tabs>
                  {filterTabs?.map((tab, idx) => {
                    return (
                      <Tab
                        label={tab.label}
                        tabIndex={tab.value}
                        key={idx}
                        isActive={currentTab === tab.value}
                        onClick={handleChangeTab}
                      />
                    );
                  })}
                </Tabs>
              </div>
              <div className={styles.tabsContent}>
                {filterTabs.map((el) => {
                  if (el.value === EnumTabs.information) {
                    return (
                      <TabPanel value={currentTab} index={el.value}>
                        <Table
                          head={tableInfoHead}
                          hideSearchField
                          rows={tableInfoRow}
                        />
                      </TabPanel>
                    );
                  } else {
                    return (
                      <TabPanel value={currentTab} index={el.value}>
                        <TabPanelItem
                          carByTrip={carByTrip}
                          hostId={hostId}
                          onHostIdClick={onHostIdClick}
                          timeline={timeline}
                          selectedIntreval={selectedIntreval}
                          setSelectedIntreval={setSelectedIntreval}
                          monitorDataType={
                            MonitorDataTypesArray[el.value] as MonitorDataTypes
                          }
                        />
                      </TabPanel>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DrawerTrack
        data={routeByTrip}
        isOpen={isOpenDrawer}
        toggleDrawer={toggleDrawer}
      />
    </Layout>
  );
}
