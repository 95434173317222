import classNames from "classnames";
import { Carriage } from "../Carriage/Carriage";
import { ReactComponent as IconLeft } from "./assets/ic_arrow_left.svg";
import { ReactComponent as IconRight } from "./assets/ic_arrow_right.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { routesVariables } from "../../../../constants";
import { generateLinkMask } from "utils";
import {
  MonitorDataItem,
  MonitorDataTypes,
} from "../../../CarriageDetail/@types";

import styles from "./carriageItem.module.sass";

type CarriageItemProps = {
  carId?: string;
  carsIds?: string[];
  monitorDataItem?: MonitorDataItem[];
  onServiceClick: (hostId: number, type: MonitorDataTypes) => void;
};

export const CarriageItem = ({
  carId,
  carsIds,
  monitorDataItem,
  onServiceClick,
}: CarriageItemProps) => {
  const currentCarIndex = carsIds?.findIndex((el) => el === carId);
  const navigate = useNavigate();
  const location = useLocation();

  const onIconClick = (index: number) => {
    if (carsIds && (index < 0 || index > carsIds?.length - 1)) return;
    carsIds &&
      navigate(
        `${generateLinkMask(routesVariables.carriageDetail, {
          id: carsIds[index],
        })}${location.search}`
      );
  };

  return (
    <div className={styles.carriageItem}>
      <div className={styles.carriageItemTitle}>
        <div
          className={classNames(
            styles.carriageItemIcon,
            currentCarIndex === 0 && styles.carriageItemIconDisabled
          )}
          onClick={() =>
            currentCarIndex !== undefined && onIconClick(currentCarIndex - 1)
          }
        >
          <IconLeft />
        </div>
        {carId}
        <div
          className={classNames(
            styles.carriageItemIcon,
            carsIds &&
              currentCarIndex === carsIds?.length - 1 &&
              styles.carriageItemIconDisabled
          )}
          onClick={() =>
            currentCarIndex !== undefined && onIconClick(currentCarIndex + 1)
          }
        >
          <IconRight />
        </div>
      </div>
      <Carriage data={monitorDataItem} onServiceClick={onServiceClick} />
    </div>
  );
};
