import { SideBarItem } from "../SideBarItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";
import { Swiper as SwiperType } from "swiper";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as IconDown } from "./assets/ic_down.svg";
import useFiltersFromUrlQueryParams from "../../../../hooks/useFiltersFromUrlQueryParams";
import { getFilialDetail } from "../../../FilialDetail/api/getFilialDetail";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { ALLOWED_FILTERS, COOKIE_AUTH_TOKEN } from "../../../../constants";
import {
  FilialDetailResponse,
  TrainCardProps,
} from "../../../FilialDetail/@types";
import { Button } from "../../../../components/Button";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

import styles from "./sildeBarSlider.module.sass";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

const ALLOWED_FILTERS_FILIAL_DETAIL = ALLOWED_FILTERS.filter(
  (el) => el !== "branches"
);

type SideBarSliderProps = {
  setFilialName?: (name: string) => void;
};

const MAX_COUNT_SLIDES_IN_VIEW = 4;

export const SideBarSlider = ({ setFilialName }: SideBarSliderProps) => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);
  const { filters, filtersIsInitialized } = useFiltersFromUrlQueryParams(
    ALLOWED_FILTERS_FILIAL_DETAIL as unknown as string[]
  ) as unknown as {
    filters: Record<(typeof ALLOWED_FILTERS_FILIAL_DETAIL)[number], string>;
    updateFilters: (...values: any[]) => void;
    filtersIsInitialized: boolean;
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const branches = searchParams.get("branches") || undefined;

  const { data, refetch, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<FilialDetailResponse | undefined>({
      queryKey: ["filialDetail", token],
      queryFn: ({ pageParam }) =>
        filtersIsInitialized
          ? getFilialDetail({
              ...filters,
              branch: branches,
              page: pageParam as number,
            })
          : undefined,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage) return;
        const countLastPage =
          lastPage?.total !==
          allPages?.reduce((acc, el) => {
            if (!el?.data) return acc;
            return acc + el?.data?.length;
          }, 0)
            ? lastPage?.page + 1
            : undefined;
        return countLastPage;
      },
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      enabled: false,
    });

  const [progress, setProgress] = useState<{
    isBeginning: boolean;
    isEnd: boolean;
  }>({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef<SwiperType>();

  useEffect(() => {
    filtersIsInitialized &&
      refetch().then((params) => {
        setProgress({
          isBeginning: swiperRef.current?.isBeginning ?? true,
          isEnd:
            (params?.data?.pages?.[0]?.data?.length || 1) >
            MAX_COUNT_SLIDES_IN_VIEW
              ? false
              : true,
        });
      });
  }, [filtersIsInitialized]);

  const filials = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return [...acc, ...(page?.data ?? [])];
    }, [] as TrainCardProps[]);
  }, [data]);

  useEffect(() => {
    setFilialName && setFilialName(data?.pages?.[0]?.branchName ?? "");
  }, [filials]);

  return (
    <div className={styles.sideBarSlider}>
      <div className={styles.sideBarSliderWrap}>
        <Swiper
          spaceBetween={16}
          height={816 - 56}
          slidesPerView="auto"
          modules={[FreeMode, Navigation]}
          direction={"vertical"}
          onBeforeInit={(swiper: SwiperType) => {
            swiperRef.current = swiper;
          }}
          onSlidesLengthChange={(swiper: SwiperType) => {
            if (swiper?.progress === 0) return;
            setProgress((prev) => ({
              isBeginning: prev?.isBeginning,
              isEnd: false,
            }));
          }}
          onReachBeginning={() => {
            setProgress((prev) => ({
              isBeginning: true,
              isEnd: prev?.isEnd,
            }));
          }}
          onReachEnd={() => {
            setProgress((prev) => ({
              isBeginning: prev?.isBeginning,
              isEnd: true,
            }));
          }}
          onSlideChange={(swiper: SwiperType) => {
            setProgress({
              isBeginning: swiper?.isBeginning,
              isEnd: swiper?.isEnd,
            });
          }}
        >
          {filials && filials.length ? (
            filials.map((item) => (
              <SwiperSlide key={item.id}>
                <SideBarItem {...item} />
              </SwiperSlide>
            ))
          ) : (
            <></>
          )}
          <SwiperSlide>
            {hasNextPage && (
              <div className={styles.showMore}>
                <Button
                  disabled={isFetching}
                  variant="contained"
                  sx={{
                    background: "rgba(61, 92, 221, 1)",
                    height: "40px",
                    textTransform: "capitalize",
                    fontFamily: "Raleway",
                    fontSize: "14px",
                    color: "#fff",
                    boxShadow: "0px 0px 8px 0px rgba(61, 92, 221, 0.08)",
                    borderRadius: "4px",
                  }}
                  onClick={() => fetchNextPage()}
                >
                  Показать еще
                </Button>
              </div>
            )}
          </SwiperSlide>
        </Swiper>
      </div>
      {!progress?.isBeginning && (
        <div
          className={styles.swiperPrev}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <IconDown />
        </div>
      )}
      {!progress?.isEnd && (
        <div
          className={styles.swiperNext}
          onClick={() => swiperRef.current?.slideNext()}
        >
          <IconDown />
        </div>
      )}
    </div>
  );
};
