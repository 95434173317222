import { FormControlLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import styles from "./radio.module.sass";

type CustomRadioProps = {
  label: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CustomRadio = ({ label, onChange, checked }: CustomRadioProps) => {
  return (
    <FormControlLabel
      sx={{ marginRight: 0 }}
      control={
        <Radio
          className={styles.radio}
          size="small"
          onChange={onChange}
          checked={checked}
        />
      }
      label={<div className={styles.radioLabel}>{label}</div>}
    />
  );
};
