import { useState, useMemo, useCallback, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox } from "../Checkbox";
import { Radio } from "../Radio";
import { ListSubheader } from "@mui/material";
import { Input, InputAdornment } from "../Input";
import { ReactComponent as IconSearch } from "./assets/ic_search.svg";
import { ReactComponent as IconArrow } from "./assets/ic_arrow.svg";
import { Button } from "../Button";
import { containsText } from "../../utils";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";

import styles from "./select.module.sass";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_WIDTH = 240;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: MENU_WIDTH,
    },
  },
  MenuListProps: {
    sx: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};

type option = {
  value: any;
  label: string;
};

type SelectProps = {
  label: string;
  labelValue: string;
  options: option[];
  disabled?: boolean;
  values?: string[];
  onSubmit: (data?: string[] | string) => void;
  type?: "checkbox" | "radio";
};

export const CustomSelect = ({
  label,
  options,
  labelValue,
  onSubmit,
  values,
  disabled,
  type = "checkbox",
}: SelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[] | string>();
  const [tmpSelectedOptions, setTmpSelectedOptions] = useState<
    string[] | string
  >();
  const [searchText, setSearchText] = useState<string>();
  const [showSelect, setShowSelect] = useState<boolean>(false);

  useEffect(() => {
    values && setSelectedOptions(Array.isArray(values) ? values : [values]);
  }, [values]);

  const displayedOptions = useMemo(
    () =>
      searchText
        ? options.filter((option) => containsText(option.label, searchText))
        : options,
    [searchText, options]
  );

  const handleChange = (value: string) => {
    if (type === "checkbox") {
      if (Array.isArray(tmpSelectedOptions)) {
        const indexVal = tmpSelectedOptions?.indexOf(value);
        const copy = [...tmpSelectedOptions];

        if (indexVal > -1) {
          copy.splice(indexVal, 1);
        } else {
          copy.push(value);
        }
        setTmpSelectedOptions(copy);
      } else {
        setTmpSelectedOptions([value]);
      }
    } else if (type === "radio") {
      setSelectedOptions(value);
      onSubmit(value);
      setShowSelect(false);
    }
  };

  const handleSubmit = useCallback(() => {
    onSubmit(tmpSelectedOptions);
    setSelectedOptions(tmpSelectedOptions);
    setShowSelect(false);
  }, [tmpSelectedOptions]);

  const Row = ({ index, style }: ListChildComponentProps) => {
    const item = displayedOptions[index];

    return (
      <MenuItem
        onClick={() => handleChange(item.value)}
        key={item.value}
        value={item.value}
        style={style}
        sx={{
          margin: "10px 0",
          whiteSpace: "normal",
        }}
      >
        {type === "radio" && (
          <Radio
            label={item.label}
            checked={
              (Array.isArray(tmpSelectedOptions) &&
                tmpSelectedOptions.findIndex(
                  (option) => option === item.value
                ) >= 0) ||
              false
            }
          />
        )}
        {type === "checkbox" && (
          <Checkbox
            label={item.label}
            checked={
              (Array.isArray(tmpSelectedOptions) &&
                tmpSelectedOptions.findIndex(
                  (option) => option === item.value
                ) >= 0) ||
              false
            }
          />
        )}
      </MenuItem>
    );
  };
  return (
    <FormControl className={styles.select}>
      <Select
        disabled={disabled}
        open={showSelect}
        onOpen={() => {
          setTmpSelectedOptions(selectedOptions);
          setShowSelect(true);
        }}
        onClose={() => {
          setShowSelect(false);
        }}
        labelId="multiple-select-label"
        id="multiple-select"
        multiple={type === "checkbox"}
        displayEmpty
        value={tmpSelectedOptions || selectedOptions || []}
        input={<OutlinedInput label={label} size="small" />}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return <div className={styles.placeholder}>{label}</div>;
          }

          const filterOptions = options
            .filter((x) =>
              (selected as unknown as Array<number>).includes(x.value)
            )
            .map((x) => x.label);

          return filterOptions.length > 1
            ? `${labelValue}: ${filterOptions.length}`
            : filterOptions[0];
        }}
        MenuProps={MenuProps}
        IconComponent={(props) => <IconArrow {...props} />}
      >
        <ListSubheader sx={{ top: "0", paddingTop: "10px" }}>
          <div className={styles.search}>
            <Input
              type="text"
              placeholder="Поиск"
              name="search"
              sx={{ background: "rgba(244, 249, 255, 1)", borderRadius: "4px" }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              inputProps={{
                autoFocus: true,
                onKeyDown: (e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </ListSubheader>
        <List
          height={ITEM_HEIGHT * 5}
          itemCount={displayedOptions?.length}
          itemSize={ITEM_HEIGHT}
          width={MENU_WIDTH}
        >
          {Row}
        </List>
        <div className={styles.menuFooter}>
          {tmpSelectedOptions && tmpSelectedOptions?.length > 0 && (
            <Button
              onClick={() => setTmpSelectedOptions(undefined)}
              variant="contained"
              fullWidth
              sx={{
                background: "rgba(215, 228, 255, 1)",
                height: "40px",
                textTransform: "capitalize",
                fontFamily: "Raleway",
                fontSize: "14px",
                color: "rgba(46, 64, 87, 1)",
                borderRadius: "4px",
              }}
            >
              Сбросить
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            sx={{
              background: "rgba(61, 92, 221, 1)",
              height: "40px",
              textTransform: "capitalize",
              fontFamily: "Raleway",
              fontSize: "14px",
              color: "#fff",
              borderRadius: "4px",
            }}
          >
            Готово
          </Button>
        </div>
      </Select>
    </FormControl>
  );
};
