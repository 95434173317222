import { api } from "../../../api";
import { LoginResponse, LoginRequest } from "../@types";

export const login = async (
  body: LoginRequest
): Promise<LoginResponse | undefined> => {
  const resp = await api.post(`/fpk/auth/login`, {
    ...body,
  });

  return resp.data;
};
