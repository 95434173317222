import {
  BrowserRouter,
  Route,
  Routes as RoutesProvider,
} from "react-router-dom";
import { routesVariables } from "../constants";
import Login from "./Login/page";
import Filials from "./Filials/page";
import FilialDetail from "./FilialDetail/page";
import CarriageDetail from "./CarriageDetail/page";
import { RequireAuth } from "components/RequireAuth";

export const Routes = () => {
  return (
    <BrowserRouter>
      <RoutesProvider>
        <Route element={<RequireAuth />}>
          <Route path={routesVariables.filials} element={<Filials />} />
          <Route
            path={routesVariables.filialDetail}
            element={<FilialDetail />}
          />
          <Route
            path={routesVariables.carriageDetail}
            element={<CarriageDetail />}
          />
        </Route>
        <Route path={routesVariables.login} element={<Login />} />
      </RoutesProvider>
    </BrowserRouter>
  );
};
