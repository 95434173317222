import { EnumLiteralsOf } from "../../../@types";

export type FilialDetailRequest = {
  beginDate: string;
  endDate: string;
  branch?: string;
  branches?: string;
  routes?: string;
  cars?: string;
  page?: number;
};

export type FilialDetailResponse = {
  branchName: string;
  page: number;
  pageSize: number;
  total: number;
  data: TrainCardProps[];
};

export type TrainCardProps = {
  arrivalDateTime: string;
  departureDateTime: string;
  id: number;
  name: string;
  route: string;
  serviceLevel: number;
  cars: CarProps[];
};

export const AllowedStatuses = Object.freeze({
  OK: "OK",
  ERROR: "ERROR",
  UNKNOWN: "UNKNOWN",
});

export type AllowedStatuses = EnumLiteralsOf<typeof AllowedStatuses>;

export type CarProps = {
  index: number;
  number: string;
  status: AllowedStatuses;
  signalSkdu: AllowedStatuses;
  signalSvnr: AllowedStatuses;
  signalWifi: AllowedStatuses;
};
