import { api } from "../../../api";
import queryString from "query-string";
import { CarDataRequest, CarDataResponse } from "../@types";
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const getCarData = async (
  params: CarDataRequest
): Promise<CarDataResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  if (!params?.tripId || !params.carId || !params?.hostId) return;

  const resp = await api.get(
    `/fpk/trips/${params?.tripId}/cars/${params.carId}/data`,
    {
      params: {
        hostId: params?.hostId,
        page: params?.page,
        beginDateTime: params?.beginDateTime,
        endDateTime: params?.endDateTime,
        pageSize: 5,
      },
      headers: {
        Authorization: token,
      },
      paramsSerializer: (params) => {
        return queryString.stringify(params);
      },
    }
  );

  return resp.data;
};
