import styles from "./page.module.sass";
import { AuthForm } from "./components/AuthForm";
import { Layout } from "../../layouts/Layout";

export default function Login() {
  return (
    <Layout>
      <div className={styles.pageWrapper}>
        <div className={styles.authWrap}>
          <AuthForm />
        </div>
      </div>
    </Layout>
  );
}
