import { ReactNode } from "react";
import { Header } from "../../components/Header";
import styles from "./layout.module.sass";

interface IProps {
  children: ReactNode;
}

export const Layout = ({ children }: IProps) => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>{children}</div>
    </div>
  );
};
