import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import styles from "./checkbox.module.sass";

type CustomCheckboxProps = {
  label: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

export const CustomCheckbox = ({
  label,
  onChange,
  defaultChecked,
  disabled,
  checked,
}: CustomCheckboxProps) => {
  return (
    <FormControlLabel
      sx={{ marginRight: 0 }}
      control={
        <Checkbox
          className={styles.checkbox}
          size="small"
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
        />
      }
      label={<div className={styles.checkboxLabel}>{label}</div>}
    />
  );
};
