import { useEffect, useMemo, useRef, useState } from "react";
import {
  TCurrentMonth,
  TDatePickerValue,
  TDayModifier,
  TDisableDays,
  TParsedDay,
} from "./types";
import {
  compareOrders,
  dateToCurrentMonth,
  getDisabledDayByLimit,
  getRangeOfDates,
  getSomeSecondDate,
  getVisibleDays,
  getWeekdays,
  isAfter,
  isBefore,
} from "./utils";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/es";
import "moment/locale/en-au";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface IUsePickerController {
  value: TDatePickerValue;
  disabledDays: TDisableDays;
  limitDays?: number;
  oneDayCalendar?: boolean;
  oneDayOrRange?: boolean;
  allowSameDay?: boolean;
  onChange: (value: { from: Date; to: Date }) => void;
  onMonthChange?: (params?: { month: number; year: number }) => void;
}

const todayKey = moment(new Date()).format("YYYY-MM-DD");

export const useDatePickerController = ({
  value,
  disabledDays,
  limitDays,
  oneDayCalendar,
  oneDayOrRange,
  allowSameDay,
  onChange,
  onMonthChange,
}: IUsePickerController) => {
  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [currentMonth, setCurrentMonth] = useState<TCurrentMonth>(() =>
    dateToCurrentMonth(value?.from ?? new Date())
  );
  const [visibleDaysToCurrentMonth, setVisibleDaysToCurrentMonth] = useState<
    TParsedDay[]
  >([]);
  const [unsaveDates, setUnsaveDates] = useState<TDatePickerValue>(null);
  const [daysModifiers, setDaysModifiers] = useState<
    Record<string, TDayModifier>
  >({
    [todayKey]: "isToday",
  });
  const [hoveredTo, setHoveredTo] = useState<Date | null>(null);
  const canChooseOneDate = oneDayCalendar || oneDayOrRange || allowSameDay;

  const handleClose = (e?: any, isNewDateSelected?: boolean) => {
    setIsOpened(false);
    setHoveredTo(null);
    setUnsaveDates(value);
    if (onMonthChange && !isNewDateSelected) {
      onMonthChange();
    }
  };

  const handleOpen = () => {
    setIsOpened(true);
    setHoveredTo(null);
    setUnsaveDates(value);
    if (value?.from) {
      setCurrentMonth(dateToCurrentMonth(value.from));
    }
  };

  const handleSelectNextMonth = () => {
    setCurrentMonth((prev) => {
      const nextMonth = prev.month === 11 ? 0 : prev.month + 1;
      const nextYear = prev.month === 11 ? prev.year + 1 : prev.year;
      const newDate = { month: nextMonth, year: nextYear };
      onMonthChange && onMonthChange(newDate);
      return newDate;
    });
  };

  const handleSelectPrevMonth = () => {
    setCurrentMonth((prev) => {
      const prevMonth = prev.month === 0 ? 11 : prev.month - 1;
      const prevYear = prev.month === 0 ? prev.year - 1 : prev.year;
      const newDate = { month: prevMonth, year: prevYear };
      onMonthChange && onMonthChange(newDate);
      return newDate;
    });
  };

  const calculatedDisabledDays: TDisableDays = useMemo(() => {
    if (limitDays && !unsaveDates?.to && unsaveDates?.from) {
      return getDisabledDayByLimit({
        selectedDate: unsaveDates.from,
        limitDays,
        disabledDays,
      });
    } else {
      return disabledDays;
    }
  }, [disabledDays, limitDays, unsaveDates]);

  const handleSave = (value?: TDatePickerValue) => {
    if ((value?.from && value.to) || (value?.from && canChooseOneDate)) {
      const toValue = value.to ?? value.from;

      const isOneDay = moment(value?.from).isSame(toValue) && !canChooseOneDate;
      const toDate = () => {
        return isOneDay
          ? getSomeSecondDate({ disabledDays, someDay: toValue })
          : toValue;
      };
      const comparedDates = compareOrders({ from: value.from, to: toDate() });

      if (comparedDates.from && comparedDates.to) {
        onChange({ from: comparedDates.from, to: comparedDates.to });
        setUnsaveDates(comparedDates);
        handleClose(undefined, true);
      }
    }
  };

  const handleSelectDay = (preparedDay: TParsedDay) => {
    if (preparedDay.monthPosition !== "currentMonth") {
      return;
    }

    const nextDates: TDatePickerValue =
      unsaveDates?.from && !unsaveDates.to
        ? {
            from: unsaveDates.from,
            to: preparedDay.day,
          }
        : {
            from: preparedDay.day,
            to: null,
          };

    if (oneDayCalendar) {
      nextDates.from = preparedDay.day;
      nextDates.to = preparedDay.day;
    }

    const comparedDates = compareOrders(nextDates);

    setUnsaveDates(comparedDates);

    if (comparedDates.from && comparedDates.to) {
      handleSave(comparedDates);
    }
  };

  const onDayHover = (preparedDay: TParsedDay) => {
    if (preparedDay.monthPosition === "currentMonth" && unsaveDates?.from) {
      setHoveredTo(preparedDay.day);
    }
  };

  useOnClickOutside([wrapperRef], handleClose);

  const weekDays = useMemo(() => {
    return getWeekdays("ru");
  }, []);

  const disableMonths = useMemo(() => {
    const prevDisabled = isBefore(
      moment().set(currentMonth).startOf("month").toDate(),
      calculatedDisabledDays.before
    );
    const nextDisabled = isAfter(
      moment().set(currentMonth).endOf("month").toDate(),
      calculatedDisabledDays.after
    );

    return { prevDisabled, nextDisabled };
  }, [calculatedDisabledDays, currentMonth]);

  useEffect(() => {
    setDaysModifiers(() => {
      const isToFromHover = hoveredTo && unsaveDates?.from;
      const nextModifiers: Record<string, TDayModifier> = {};
      let fromKey: string | null = null;
      let toKey: string | null = null;
      let comparedDatesFromHover: TDatePickerValue = null;
      if (hoveredTo && unsaveDates?.from) {
        comparedDatesFromHover = compareOrders({
          from: unsaveDates?.from,
          to: hoveredTo,
        });
        if (comparedDatesFromHover.from && comparedDatesFromHover.to) {
          fromKey = moment(comparedDatesFromHover.from).format("YYYY-MM-DD");
          toKey = moment(comparedDatesFromHover.to).format("YYYY-MM-DD");
        }
      } else {
        fromKey = unsaveDates?.from
          ? moment(unsaveDates.from).format("YYYY-MM-DD")
          : null;
        toKey = unsaveDates?.to
          ? moment(unsaveDates.to).format("YYYY-MM-DD")
          : null;
      }

      if (fromKey) {
        nextModifiers[fromKey] = "isStartOfRange";
      }

      if (toKey) {
        nextModifiers[toKey] = "isEndOfRange";
      }

      if (toKey && fromKey && fromKey === toKey && canChooseOneDate) {
        nextModifiers[toKey] = "isOneDaySelected";
      }

      const inRangeKeys = isToFromHover
        ? getRangeOfDates(comparedDatesFromHover)
        : getRangeOfDates(unsaveDates);

      inRangeKeys.forEach((key) => (nextModifiers[key] = "inRange"));

      if (!nextModifiers[todayKey]) {
        nextModifiers[todayKey] = "isToday";
      }

      return nextModifiers;
    });
  }, [unsaveDates, hoveredTo, canChooseOneDate]);

  useEffect(() => {
    setUnsaveDates(value);
  }, [JSON.stringify(value)]);

  useEffect(() => {
    setVisibleDaysToCurrentMonth(getVisibleDays(currentMonth));
  }, [currentMonth]);

  return {
    visibleDaysToCurrentMonth,
    currentMonth,
    isOpened,
    wrapperRef,
    weekDays,
    daysModifiers,
    unsaveDates,
    calculatedDisabledDays,
    disableMonths,
    handleSave,
    handleOpen,
    handleSelectNextMonth,
    handleSelectPrevMonth,
    handleSelectDay,
    onDayHover,
    handleClose,
  };
};
