import {
  TextField,
  SxProps,
  InputAdornment,
  OutlinedInputProps,
} from "@mui/material";
import styles from "./input.module.sass";
import { ChangeEvent } from "react";

type InputPropss = {
  label?: string;
  type: string;
  placeholder: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<{}>;
  inputProps?: Partial<OutlinedInputProps>;
  required?: boolean;
};

export const Input = (props: InputPropss) => {
  return (
    <div className={styles.input}>
      {props.label && <div className={styles.inputLabel}>{props.label}</div>}
      <TextField
        variant="outlined"
        sx={props.sx}
        fullWidth
        type={props.type}
        required={props?.required}
        id={props.name}
        size="small"
        placeholder={props.placeholder}
        InputProps={{
          name: props.name,
          onChange: props.onChange,
          ...props.inputProps,
        }}
      />
    </div>
  );
};

export { InputAdornment };
