import { api } from "../../../api";
import queryString from "query-string";
import { CarDataProblemsRequest, CarDataProblemsResponse } from "../@types";
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "../../../constants";

export const getCarProblemsData = async (
  params: CarDataProblemsRequest
): Promise<CarDataProblemsResponse | undefined> => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);

  if (!params?.tripId || !params.carId || !params?.hostId) return;

  const resp = await api.get(
    `/fpk/trips/${params?.tripId}/cars/${params.carId}/problems`,
    {
      params: {
        hostId: params?.hostId,
        page: params?.page,
        pageSize: 5,
      },
      headers: {
        Authorization: token,
      },
      paramsSerializer: (params) => {
        return queryString.stringify(params);
      },
    }
  );

  return resp.data;
};
