import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Input, InputAdornment } from "../Input";
import { ReactComponent as SearchIcon } from "./assets/ic_search.svg";
import uniqid from "uniqid";
import { containsText } from "../../utils";

import styles from "./table.module.sass";

export type CellAligns = "inherit" | "left" | "center" | "right" | "justify";

type CustomizedTableProps = {
  head: {
    title: string;
    align: CellAligns;
  }[];
  rows?: {
    data: (string | React.ReactNode)[];
    id: string;
  }[];
  title?: string;
  onRowClick?: (id: string) => void;
  hideSearchField?: boolean;
};

export const CustomizedTable = ({
  head,
  rows,
  title,
  onRowClick,
  hideSearchField = false,
}: CustomizedTableProps) => {
  const [searchText, setSearchText] = React.useState<string>();

  const displayedRows = React.useMemo(
    () =>
      searchText
        ? rows?.filter((row) => typeof row?.data[0] === 'string' && containsText(row?.data[0] || "", searchText))
        : rows,
    [searchText, rows]
  );

  return (
    <TableContainer component={Paper}>
      {title && (
        <div className={styles.tableHeader}>
          <div className={styles.title}>{title}</div>
          {!hideSearchField && (
            <div className={styles.search}>
              <Input
                type="text"
                placeholder="Поиск"
                name="search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                sx={{
                  background: "rgba(244, 249, 255, 1)",
                  borderRadius: "4px",
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </div>
      )}

      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow
            sx={{
              border: "1px solid rgba(226, 236, 244, 1)",
              background: "rgba(244, 249, 255, 1)",
            }}
          >
            {head &&
              head.map((headItem, idx) => {
                return (
                  <TableCell
                    key={idx}
                    sx={{
                      fontFamily: "Raleway",
                      fontWeight: 600,
                      fontSize: "12px",
                      color: "rgba(46, 64, 87, 1)",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                    align={headItem.align}
                  >
                    {headItem.title}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedRows &&
            displayedRows.map((row, index) => (
              <TableRow
                onClick={() => onRowClick && onRowClick(row?.id)}
                key={index}
                sx={{
                  cursor: "pointer",
                  background:
                    index % 2 === 0 ? "white" : "rgba(244, 249, 255, 1)",
                }}
              >
                {head &&
                  head.map((headItem, idx) => {
                    return (
                      <TableCell
                        key={uniqid()}
                        sx={{
                          borderBottom: "none",
                          fontFamily: "Raleway",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "rgba(46, 64, 87, 1)",
                          paddingTop: "11px",
                          paddingBottom: "11px",
                        }}
                        align={headItem.align}
                      >
                        {row?.data?.[idx]}
                      </TableCell>
                    );
                  })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
