import { routesVariables } from "../../../../constants";
import { CellAligns, Table } from "../../../../components/Table";
import { useLocation, useNavigate } from "react-router-dom";
import { generateLinkMask } from "utils";
import { TableFilialsRow } from "containers/Filials/@types";

import styles from "./table.module.sass";

type TableFilialsProps = {
  data?: TableFilialsRow[];
};

export const TableFilials = ({ data }: TableFilialsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const rows =
    (data &&
      data.map((filial) => ({
        id: filial?.id,
        data: [
          filial?.name,
          filial?.countTrain,
          filial?.countTrainProblem,
          filial?.serviceLevel,
        ],
      }))) ||
    [];

  const head = [
    {
      title: "Филиал",
      align: "left" as CellAligns,
    },
    {
      title: "Количество поездов",
      align: "right" as CellAligns,
    },
    {
      title: "Поездов с проблемами",
      align: "right" as CellAligns,
    },
    {
      title: "Показатель уровня сервиса",
      align: "right" as CellAligns,
    },
  ];

  const onRowClick = (id: string) => {
    const search = new URLSearchParams(location.search);
    search.set("branches", id);

    navigate(
      `${generateLinkMask(routesVariables.filialDetail, {
        id,
      })}?${search.toString()}`
    );
  };

  return (
    <div className={styles.table}>
      <Table
        onRowClick={onRowClick}
        head={head}
        rows={rows}
        title={"Список филиалов"}
      />
    </div>
  );
};
