import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { getLineOptions } from "./utils";
import { ReactComponent as IconArrowDownload } from "./assets/ic_arrow_download.svg";
import classNames from "classnames";

import styles from "./chart.module.sass";

export type DataSeries = {
  name: string;
  type: string;
  smooth: boolean;
  data: (string | number)[][];
  id: string;
  animation: boolean;
  markLine: {
    silent: boolean;
    lineStyle: {
      opacity: number;
      color: string;
    };
    data: {
      name: string;
      yAxis: number;
    }[];
    animation: boolean;
  };
};

type legend = {
  id: number;
  name: string;
};

type ChartProps = {
  title?: string;
  series: DataSeries[];
  graphId: number;
  legends: legend[];
  handleDownload?: (graphId: number, graphItemIds: number[]) => void;
  disabledDownload?: boolean;
};

type EChartsProps = {
  series: DataSeries[];
  showTreeshold?: boolean;
  handleSetCurrentLegend?: (legends: string[]) => void;
  onAfterShowTreeshold?: (flag: boolean) => void;
};

const DISABLED_DOWNLOAD_TIMEOUT = 3000;

export const Chart = ({
  title,
  series,
  graphId,
  legends,
  handleDownload,
}: ChartProps) => {
  const [currentLegend, setCurrentLegend] = useState<legend[]>(legends);
  const [showHideTreesholdButton, setShowHideTreesholdButton] = useState(false);
  const [showTreeshold, setShowTreeshold] = useState(true);
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const onAfterShowTreeshold = (flag: boolean) => {
    setShowHideTreesholdButton(flag);
    setShowTreeshold(flag);
  };

  const onChartHideTreesholdClick = () => {
    setShowTreeshold((prev) => !prev);
  };

  const onChartDownload = () => {
    setDownloadDisabled(true);
    const graphsId = currentLegend.map((el) => el.id);
    handleDownload && handleDownload(graphId, graphsId);
    setTimeout(() => {
      setDownloadDisabled(false);
    }, DISABLED_DOWNLOAD_TIMEOUT);
  };

  const handleSetCurrentLegend = (names: string[]) => {
    const copyLegends = [...legends];
    const filterLegends = copyLegends.filter((el) => names.includes(el.name));
    setCurrentLegend(filterLegends);
  };

  return (
    <div className={styles.chartWrap}>
      <div className={styles.chartHeader}>
        <div className={styles.chartTitle}>{title}</div>
        <div className={styles.chartGroupButtons}>
          <div
            className={classNames(
              styles.chartDownload,
              downloadDisabled && styles.chartDownloadDisabled
            )}
            onClick={onChartDownload}
          >
            <IconArrowDownload />
            Скачать в Excel
          </div>
          {showHideTreesholdButton && (
            <div
              className={styles.chartHideTreeshold}
              onClick={onChartHideTreesholdClick}
            >
              {showTreeshold
                ? "Скрыть пороговые значения"
                : "Показать пороговые значения"}
            </div>
          )}
        </div>
      </div>

      <ECharts
        series={series}
        showTreeshold={showTreeshold}
        handleSetCurrentLegend={handleSetCurrentLegend}
        onAfterShowTreeshold={onAfterShowTreeshold}
      />
    </div>
  );
};

const ECharts = (props: EChartsProps) => {
  const {
    series,
    onAfterShowTreeshold,
    showTreeshold,
    handleSetCurrentLegend,
  } = props;
  const [chart, setChart] = useState<echarts.ECharts>();
  const chartRef = useRef<HTMLDivElement>(null);
  const currentOptions = useRef<any>();

  useEffect(() => {
    if (!chartRef.current) return;
    const options = getLineOptions(series);
    series.forEach((el) => {
      options.legend.selected[el.name] = true;
    });
    const chart = echarts.init(chartRef.current, null, {
      renderer: "svg",
    });
    //@ts-ignore
    chart.setOption({ ...options, resizeObserver }, true);
    currentOptions.current = options;
    setChart(chart);

    if (
      options.series.length === 1 &&
      options.series[0].markLine?.data?.length > 0
    ) {
      onAfterShowTreeshold && onAfterShowTreeshold(true);
    }

    chart.on(
      "legendselectchanged",
      (params: {
        type: "legendselectchanged";
        name: string;
        selected: {
          [name: string]: boolean;
        };
      }) => {
        const filterParams = Object.keys(params.selected).filter(
          (key) => params.selected[key]
        );

        handleSetCurrentLegend && handleSetCurrentLegend(filterParams);

        if (filterParams.length === 1) {
          const findIndexSeries = series.findIndex(
            (el) => el.name === filterParams[0]
          );

          if (
            findIndexSeries &&
            series[findIndexSeries]?.markLine?.data?.length > 0
          ) {
            series[findIndexSeries].markLine.lineStyle.opacity = 1;
            const newOptions = getLineOptions(series);
            onAfterShowTreeshold && onAfterShowTreeshold(true);

            newOptions.series.forEach((el, index) => {
              if (index !== findIndexSeries) {
                newOptions.legend.selected[el.name] = false;
              } else {
                newOptions.legend.selected[el.name] = true;
              }
            });

            //@ts-ignore
            chart.setOption({ ...newOptions }, true);
            currentOptions.current = newOptions;
          }
        } else {
          series.forEach((el) => (el.markLine.lineStyle.opacity = 0));
          const newOptions = getLineOptions(series);
          onAfterShowTreeshold && onAfterShowTreeshold(false);

          newOptions.legend.selected = {
            ...newOptions.legend.selected,
            ...params.selected,
          };

          //@ts-ignore
          chart.setOption({ ...newOptions }, true);
          currentOptions.current = newOptions;
        }
      }
    );
    if (resizeObserver) resizeObserver.observe(chartRef.current);
  }, [series]);

  useEffect(() => {
    return () => {
      chart && chart.clear();
    };
  }, []);

  useEffect(() => {
    if (!chart || !currentOptions.current || showTreeshold === undefined)
      return;
    if (showTreeshold) {
      currentOptions.current.series.forEach((series: any) => {
        series.markLine.lineStyle.opacity = 1;
      });
    } else {
      currentOptions.current.series.forEach((series: any) => {
        series.markLine.lineStyle.opacity = 0;
      });
    }
    //@ts-ignore
    chart.setOption({ ...currentOptions.current }, true);
  }, [showTreeshold]);

  return (
    <div className={styles.echart}>
      <div ref={chartRef} className={styles.chart} />
    </div>
  );
};

const resizeObserver = new window.ResizeObserver((entries) => {
  entries.map(({ target }: ResizeObserverEntry) => {
    const instance = echarts.getInstanceByDom(target as HTMLDivElement);
    if (instance) {
      instance.resize();
    }
  });
});

export default React.memo(ECharts);
