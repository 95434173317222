/**
 * Есть ли в тексте набор символов
 * @param text - строка в которой ищем
 * @param searchText - строка которую ищем
 */
export const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

/**
 * Генерация ссылки исходя из указанного шаблона
 * @param template - шаблон ссылки
 * @param params - параметры, которые необходимо вставить в шаблон
 */
export function generateLinkMask(
  template: string,
  params: { [key: string]: string | number }
) {
  let formattedString = template;
  const matches = formattedString.match(/:[a-zA-Z0-9()]*/g);
  if (matches) {
    matches.forEach((i: string) => {
      const variable = i.replace(/:/g, "");
      const matchData = params[variable];
      if (matchData) {
        formattedString = formattedString.replace(i, matchData.toString());
      }
    });
  }
  return formattedString;
}
