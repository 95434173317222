import { useLocation, Navigate, Outlet } from "react-router-dom";
import { COOKIE_AUTH_TOKEN, routesVariables } from "../../constants";
import Cookies from "js-cookie";

export const RequireAuth = () => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN);
  const location = useLocation();

  if (!token)
    return <Navigate to={routesVariables.login} state={{ from: location }} />;

  return <Outlet />;
};
