import { TimelineResponse, Transit } from "../../@types";
import classNames from "classnames";
import { Tab, TabPanel, Tabs } from "../../../../components/Tabs";
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";
import { Swiper as SwiperType } from "swiper";
import { ReactComponent as IconLeft } from "./assets/ic_left.svg";
import { ReactComponent as IconRight } from "./assets/ic_right.svg";
import { ReactComponent as IconClose } from "./assets/ic_close.svg";
import { Tooltip } from "../../../../components/Tooltip";
import { Table, CellAligns } from "../../../../components/Table";
import moment from "moment";

import styles from "./trackSlider.module.sass";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

type TrackSliderProps = {
  timeline?: TimelineResponse;
  selectedInterval?: {
    startDate: string;
    endDate: string;
  };
  setSelectedIntreval?: (selectedInterval?: {
    startDate: string;
    endDate: string;
  }) => void;
};

export const TrackSlider = ({
  timeline,
  selectedInterval,
  setSelectedIntreval,
}: TrackSliderProps) => {
  if (!timeline) return <></>;
  const { transits } = timeline;
  const [currentTab, setCurrentTab] = useState<number>(0);
  const swiperRef = useRef<SwiperType>();
  const [progress, setProgress] = useState<{
    isBeginning: boolean;
    isEnd: boolean;
  }>({
    isBeginning: true,
    isEnd: false,
  });
  const [selectedStation, setSelectedStation] = useState<{
    station: string;
    startDate: string;
    endDate: string;
  }>();

  const handleChangeTab = (newValue: number) => {
    setCurrentTab(newValue);
  };

  const departureStation = transits[0];

  const arrivalStation = transits[transits.length - 1];

  useEffect(() => {
    setSelectedStation({
      station: `${departureStation?.fromStation?.stationName} - ${arrivalStation?.toStation?.stationName}`,
      startDate: `${departureStation?.fromStation?.departureDate} ${departureStation?.toStation?.departureTime}`,
      endDate: `${arrivalStation?.fromStation?.arrivalDate} ${arrivalStation?.toStation?.arrivalTime}`,
    });
  }, []);

  const groupsIntermediateStations = transits.reduce(
    (groups, item) => ({
      ...groups,
      [item.day]: [...((groups[item.day] as Transit[]) || []), item],
    }),
    {} as { [key: string]: Transit[] }
  );

  const tableHead = [
    {
      title: "Станция",
      align: "left" as CellAligns,
    },
    {
      title: "Прибытие",
      align: "left" as CellAligns,
    },
    {
      title: "Стоянка",
      align: "left" as CellAligns,
    },
    {
      title: "Отправление",
      align: "left" as CellAligns,
    },
  ];

  const handleClear = () => {
    setSelectedStation({
      station: `${departureStation?.fromStation?.stationName} - ${arrivalStation?.toStation?.stationName}`,
      startDate: `${departureStation?.fromStation?.departureDate} ${departureStation?.toStation?.departureTime}`,
      endDate: `${arrivalStation?.fromStation?.arrivalDate} ${arrivalStation?.toStation?.arrivalTime}`,
    });
    setSelectedIntreval && setSelectedIntreval(undefined);
  };

  return (
    <div className={styles.trackSliderWrap}>
      <div className={styles.trackSliderHeader}>
        <div
          className={classNames(
            styles.trackSliderHeaderItem,
            styles.trackSliderHeaderItemTabs
          )}
        >
          <div className={styles.trackSliderHeaderItemTabTitle}>Маршрут</div>
          <div className={styles.trackSliderHeaderItemTabSubTitle}>
            День
            <Tabs tabsClassName={styles.tabsWrapper}>
              {Object.keys(groupsIntermediateStations)?.map((tab, idx) => {
                return (
                  <Tab
                    tabClassname={styles.tabWrapper}
                    label={tab}
                    tabIndex={idx}
                    key={idx}
                    onClick={handleChangeTab}
                    isActive={idx === currentTab}
                  />
                );
              })}
            </Tabs>
          </div>
        </div>
        <div
          className={classNames(
            styles.trackSliderHeaderItem,
            styles.trackSliderHeaderItemRoute
          )}
        >
          <div className={styles.trackSliderHeaderItemRouteTitle}>
            {selectedStation?.station}
          </div>
          <div className={styles.trackSliderHeaderItemRouteSubTitle}>
            {selectedStation?.startDate} - {selectedStation?.endDate}
          </div>
        </div>
        <div className={styles.trackSliderHeaderItem}></div>
      </div>
      {Object.keys(groupsIntermediateStations)?.map((tab, idx) => {
        return (
          <TabPanel value={currentTab} index={idx}>
            <div className={styles.trackSlider}>
              <Swiper
                spaceBetween={0}
                slidesPerView="auto"
                modules={[FreeMode, Navigation]}
                onBeforeInit={(swiper: SwiperType) => {
                  swiperRef.current = swiper;
                }}
                onSlideChange={(swiper: SwiperType) => {
                  setProgress({
                    isBeginning: swiper?.isBeginning,
                    isEnd: swiper?.isEnd,
                  });
                }}
                onReachBeginning={() => {
                  setProgress((prev) => ({
                    isBeginning: true,
                    isEnd: prev?.isEnd,
                  }));
                }}
                onReachEnd={() => {
                  setProgress((prev) => ({
                    isBeginning: prev?.isBeginning,
                    isEnd: true,
                  }));
                }}
                onInit={(swiper: SwiperType) => {
                  setTimeout(() => {
                    setProgress({
                      isBeginning: true,
                      isEnd: swiper?.isEnd,
                    });
                  }, 200);
                }}
              >
                {groupsIntermediateStations?.[tab]?.map((el, idx) => {
                  const tableRow = [
                    {
                      data: [
                        el?.fromStation?.stationName,
                        el?.fromStation?.arrivalTime,
                        el?.fromStation?.stopDuration,
                        el?.fromStation?.departureTime,
                      ],
                      id: `${idx}`,
                    },
                    {
                      data: [
                        el?.toStation?.stationName,
                        el?.toStation?.arrivalTime,
                        el?.toStation?.stopDuration,
                        el?.toStation?.departureTime,
                      ],
                      id: `${idx}`,
                    },
                  ];

                  const arrivalTimeStation =
                    el?.fromStation?.arrivalTime ||
                    el?.fromStation?.departureTime;
                  const departureTimeStation = el?.toStation?.arrivalTime;

                  const startDateSelectedInterval = moment(
                    `${el?.fromStation?.departureDate} ${arrivalTimeStation}`,
                    "YYYY-MM-DD HH:mm"
                  ).format("YYYY-MM-DDTHH:mm:ss");

                  const endDateSelectedInterval = moment(
                    `${
                      el?.toStation?.departureDate || el?.toStation?.arrivalDate
                    } ${departureTimeStation}`,
                    "YYYY-MM-DD HH:mm"
                  ).format("YYYY-MM-DDTHH:mm:ss");

                  const isActive =
                    moment(startDateSelectedInterval).isSame(
                      selectedInterval?.startDate
                    ) &&
                    moment(endDateSelectedInterval, "YYYY-MM-DD HH:mm").isSame(
                      selectedInterval?.endDate
                    );

                  return (
                    <SwiperSlide key={idx}>
                      <div className={styles.trackSliderItemWrap}>
                        <Tooltip
                          title={
                            <div className={styles.tooltipWrap}>
                              <div className={styles.tooltipHeader}>
                                <div className={styles.tooltipTitle}>
                                  {el?.fromStation?.stationName} -{" "}
                                  {el?.toStation?.stationName}
                                </div>
                                <div className={styles.tooltipSubTitle}>
                                  {el?.duration} в пути
                                </div>
                              </div>
                              <div className={styles.tooltipContent}>
                                <Table
                                  head={tableHead}
                                  hideSearchField
                                  rows={tableRow}
                                />
                              </div>
                            </div>
                          }
                          arrow
                          slotProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                maxWidth: "345px",
                                boxShadow:
                                  "0px 0px 16px 0px rgba(46, 64, 87, 0.5)",
                              },
                            },
                            arrow: {
                              sx: {
                                color: "rgba(255, 255, 255, 1)",
                              },
                            },
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -8],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <div
                            className={classNames(
                              styles.trackSliderItem,
                              isActive && styles.trackSliderItemActive,
                              !el?.enabled && styles.trackSliderItemDisabled,
                              "trackSliderItemBorder"
                            )}
                          >
                            <div
                              className={styles.trackSliderItemText}
                              onClick={() => {
                                if (el?.enabled) {
                                  setSelectedIntreval &&
                                    setSelectedIntreval({
                                      startDate: startDateSelectedInterval,
                                      endDate: endDateSelectedInterval,
                                    });
                                  setSelectedStation({
                                    station: `${el?.fromStation?.stationName} - ${el?.toStation?.stationName}`,
                                    startDate: moment(
                                      startDateSelectedInterval
                                    ).format("YYYY-MM-DD HH:mm"),
                                    endDate: moment(
                                      endDateSelectedInterval
                                    ).format("YYYY-MM-DD HH:mm"),
                                  });
                                }
                              }}
                            >
                              {arrivalTimeStation} - {departureTimeStation}
                            </div>
                            {isActive && (
                              <div
                                className={styles.trackSliderItemClose}
                                onClick={handleClear}
                              >
                                <IconClose />
                              </div>
                            )}
                          </div>
                        </Tooltip>
                        <div className={styles.trackSliderItemCaption}>
                          {el?.toStation?.stationName}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {!progress?.isBeginning && (
                <div
                  className={styles.swiperPrev}
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <IconLeft />
                </div>
              )}
              {!progress?.isEnd && (
                <div
                  className={styles.swiperNext}
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <IconRight />
                </div>
              )}
            </div>
          </TabPanel>
        );
      })}
    </div>
  );
};
